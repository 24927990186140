<button mat-flat-button appDebounceClick [debounceTime]="debounceTime"
        tabindex="-1"
        [disableRipple]="type !== ButtonType.PRIMARY"
        [ngClass]="type"
        [routerLink]="routerLink"
        [queryParams]="queryParams"
        [disabled]="disabled"
        (debounceClick)="onClick()">
  <div class="y3-flex-row y3-flex-align-center">
    <mat-icon *ngIf="leftIcon" class="margin-right-8" svgIcon="{{leftIcon}}"></mat-icon>
    <mat-icon *ngIf="leftBrutIcon" class="margin-right-4">{{leftBrutIcon}}</mat-icon>
    <div>{{text | translate}}</div>
    <mat-icon *ngIf="rightIcon" class="margin-right-8" svgIcon="{{rightIcon}}"></mat-icon>
  </div>
</button>
