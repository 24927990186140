import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HttpService {
  getHeaders(token: string): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('authorization', `Bearer ${token}`)
        .set('Content-Type', 'application/json')
    };
  }

  getHeadersForFileDownload(token: string) {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      // have to explicitly give as 'blob' or 'json'
      responseType: 'blob' as 'blob'
    };
    return options;

  }

  getTextHeaders(token: string): { headers: HttpHeaders, responseType } {
    return {
      headers: new HttpHeaders()
        .set('authorization', `Bearer ${token}`)
        .set('Content-Type', 'application/json')
      , responseType: 'text'
    };
  }

  getContentAndTextHeaders(token: string): { headers: HttpHeaders, responseType } {
    return {
      headers: new HttpHeaders()
        .set('authorization', `Bearer ${token}`)
        .set('Content-Type', 'text/plain; charset=utf-8')
      , responseType: 'text'
    };
  }



  getBlobHeaders(token: string): { headers?: HttpHeaders, responseType } {
    return {
      headers: new HttpHeaders()
        .set('authorization', `Bearer ${token}`)
        .set('Content-Type', 'application/json')
      , responseType: 'blob' as 'json'
    };
  }

  getSimpleHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    };
  }

  getHeadersForUpload(token: string): { headers: HttpHeaders } {
    return { headers: new HttpHeaders()
        .set('authorization', `Bearer ${token}`)
    };
  }

  getOptionsWithParams(token: string, httpParams: HttpParams) {
    return {
      headers: new HttpHeaders()
        .set('authorization', `Bearer ${token}`)
        .set('Content-Type', 'application/json'), params: httpParams};
  }

  getSimpleOptionsWithParams(httpParams: HttpParams) {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json'), params: httpParams};
  }


  getHeadersNoCors(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Headers', 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Origin, Authorization, Basic')
        .set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, DELETE')
    };
  }
  getEmptyHeadersForUpload(): { headers: HttpHeaders } {
    return { headers: new HttpHeaders()};
  }
}
