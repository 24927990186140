import {Injectable} from '@angular/core';
import {AirbnbCredentials} from '../../../model/airbnb/AirbnbCredentials';
import {Observable} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {IglooHomeLock} from '../../../model/partners/igloohome/IglooHomeLock';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HttpService} from '../HttpService';
import {TokenService} from '../TokenService';
import {ConfService} from '../../services/ConfService';
import {Person} from '../../../model/Person';
import {IglooHomePartnerInfo, Property} from '../../../model/Property';
import { PersonService } from '../../services/YaagoServices/PersonService';
import {SwiklyBulkUpdate} from "../../../model/swikly/SwiklyBulkUpdate";
import {IgloohomeBulkUpdate} from "../../../model/igloohome/IgloohomeBulkUpdate";
import {CorporateIgloohomeConnect} from "../../../model/partners/igloohome/CorporateIgloohomeConnect";

@Injectable({ providedIn: 'root' })
export class IgloohomeEndpoint {
  constructor(private http: HttpClient,
              private httpService: HttpService,
              private tokenService: TokenService,
              private confService: ConfService,
              private personService: PersonService) {
  }

  me: Person;

  getIgloohomeConnect(corporateId: string): Observable<CorporateIgloohomeConnect> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<CorporateIgloohomeConnect>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/iglooconnect/' + corporateId, this.httpService.getHeaders(to));
    }));
  }

  refreshIgloohomeConnect(): Observable<CorporateIgloohomeConnect> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<CorporateIgloohomeConnect>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/refresh', this.httpService.getHeaders(to));
    }));
  }

  removeIgloohomeConnect(corporateId: string, id_token: string ): Observable<void> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<void>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/iglooconnect/remove/' + corporateId + '/' + id_token, this.httpService.getHeaders(to));
    }));
  }

  changeShowButton(corporateId: string, id_token: string ): Observable<CorporateIgloohomeConnect> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<CorporateIgloohomeConnect>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/iglooconnect/change-show-button/' + corporateId + '/' + id_token, this.httpService.getHeaders(to));
    }));
  }

  setApikey(apiKey: string): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/apiKey/' + apiKey, null, this.httpService.getHeaders(to));
    }));
  }
  /*
  setApikey2(apiKey: string): Observable<Person>{
    let headers=new HttpHeaders();
    headers.append('Authorization',"Bearer "+localStorage.getItem("token"));
    return this.http.put<Person>(this.confService.getUrlApiEndPoint() + "api-v2/igloohome/apiKey/" + apiKey,{headers:
      new HttpHeaders({"Authorization":"Bearer "+ localStorage.getItem("yaago_auth_token")})});
  }
  */
  removeApiKey(): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/apiKey', this.httpService.getHeaders(to));
    }));
  }

  getAvailableLocks(): Observable<IglooHomeLock[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<IglooHomeLock[]>(
        this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/availablelocks', this.httpService.getHeaders(to)
      );
    }));
  }

  getAvailableLocksByPropertyId(propertyId: string): Observable<IglooHomeLock[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<IglooHomeLock[]>(
        this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/availablelocks/' + propertyId, this.httpService.getHeaders(to)
      );
    }));
  }

  isApiKeyExist(): boolean {
    this.me = this.personService.getCurrentUser();
    if(this.me && this.me.host){
      if(typeof this.me.corporate.iglooHome !== 'undefined' && this.me.corporate.iglooHome !== null){
        if(Object.keys(this.me.corporate.iglooHome).length > 0){
          if(this.me.corporate.iglooHome.apiKey !== "" && this.me.corporate.iglooHome.apiKey !== null && typeof this.me.corporate.iglooHome.apiKey !== "undefined"){
            return true;
          }
        }
      }
    }
    return false;
  }

  refreshLocks(): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/', this.httpService.getHeaders(to));
    }));
  }
  refreshLocksByPropertyId(propertyId: string): Observable<IglooHomeLock[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<IglooHomeLock[]>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/locks/' + propertyId, this.httpService.getHeaders(to));
    }));
  }
  setLockOnProperty(propertyId: string, iglooHomePartnerInfo: IglooHomePartnerInfo): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/property/' + propertyId , iglooHomePartnerInfo, this.httpService.getHeaders(to));
    }));
  }

  saveLockInfo(propertyId: string, iglooHomePartnerInfo: IglooHomePartnerInfo): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/property/save-info/' + propertyId , iglooHomePartnerInfo, this.httpService.getHeaders(to));
    }));
  }

  removeLockOnProperty(propertyId: string): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/property/' + propertyId , this.httpService.getHeaders(to));
    }));
  }

  bulkEdit(igloohomeBulkUpdate: IgloohomeBulkUpdate[]) : Observable<string[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<string[]>(this.confService.getUrlApiEndPoint() + 'api-v2/igloohome/bulkUpdate', igloohomeBulkUpdate, this.httpService.getHeaders(to));
    }));
  }

}
