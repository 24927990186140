import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/internal/operators';
import {ContractTemplate, Corporate} from 'src/app/model/Corporate';
import { Person } from 'src/app/model/Person';
import { PersonInfo } from 'src/app/model/PersonInfo';
import { ConfService } from '../../services/ConfService';
import { HttpService } from '../HttpService';
import { TokenService } from '../TokenService';
import {ReturnUrl} from "../../../model/secondary/ReturnUrl";
import {RegisterCorporate} from "../../../model/register-corporate/RegisterCorporate";

@Injectable({ providedIn: 'root' })
export class CorporateEndpoint {

  constructor(private http: HttpClient,
    private httpService: HttpService,
    private tokenService: TokenService,
    private confService: ConfService) {
  }

  getCorporateById(id: string): Observable<Corporate> {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.get<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/' + id, this.httpService.getHeaders(to));
    }));
  }

  resendPostmarkEmail(id: string): Observable<Corporate> {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.get<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/resend-postmark/' + id, this.httpService.getHeaders(to));
    }));
  }

  updateCorporate(corporate: Corporate): Observable<Corporate> {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.post<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate' , corporate, this.httpService.getHeaders(to));
    }));
  }

  getMembers(corporate: Corporate): Observable<PersonInfo[]> {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.get<PersonInfo[]>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/' + corporate.id + '/members', this.httpService.getHeaders(to));
    }));
  }

  addMember(corporate: Corporate, person: PersonInfo, role: string, message: string): Observable<Corporate> {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.post<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/member/' + corporate.id + "/" + role,
        { "member" : person, "message": message }, this.httpService.getHeaders(to));
    }));
  }

  removeMember(corporate: Corporate, personId: string): Observable<Corporate> {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.delete<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/member/' + corporate.id + "/" + personId,
          this.httpService.getHeaders(to));
    }));
  }

  checkMember(email: string): Observable<PersonInfo> {
    let httpParams = new HttpParams().set('email', email);
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.get<PersonInfo>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/member/check',
          this.httpService.getOptionsWithParams(to, httpParams));
    }));
  }

  createStripeAccountLink(corporateId: string): Observable<ReturnUrl> {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.get<ReturnUrl>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/createaccountlink/' + corporateId, this.httpService.getHeaders(to));
    }));
  }

  uploadContract(corporateId: string, contractTemplate: ContractTemplate): Observable<Corporate> {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.put<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/upload-contract/' + corporateId, contractTemplate , this.httpService.getHeaders(to));
    }));
  }

  removeContract(corporateId: string, language: string): Observable<Corporate> {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.delete<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/remove-contract/' + corporateId + '/' + language, this.httpService.getHeaders(to));
    }));
  }

  uploadCgv(corporateId: string, contractTemplate: ContractTemplate): Observable<Corporate> {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.put<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/upload-cgv/' + corporateId, contractTemplate , this.httpService.getHeaders(to));
    }));
  }

  removeCgv(corporateId: string, language: string): Observable<Corporate> {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.delete<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/remove-cgv/' + corporateId + '/' + language, this.httpService.getHeaders(to));
    }));
  }

  registerCorporate(registerCorporateDto: RegisterCorporate) {
    return this.tokenService.getNewToken().pipe(flatMap(to => {
      return this.http.post<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/corporate/register', registerCorporateDto, this.httpService.getSimpleHeaders());
    }));
  }

}
