export class ThreadSearchRequest {
  bookingRangeStart: string;
  bookingRangeEnd: string;
  onlyWithUnreadMessages: boolean;

  incomingMessageRangeStart: string;
  incomingMessageRangeEnd: string;
  recipientType: string;

  bookingTextSearch: string;
  propertyTextSearch: string;

  sorts: RequestSort;
  pageSize: number;
  pageNumber: number;
}
export class RequestSort {
  field: string;
  direction: number;
}
