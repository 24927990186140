import {HostAirbnbData} from './person/HostAirbnbData';
import {HostBookingData} from './person/HostBookingData';
import {HostIglooHomeData} from '../partners/igloohome/HostIglooHomeData';
import {HostTheKeysData} from "../partners/theKeys/HostTheKeysData";
import {HostMinutData} from "../partners/minut/HostMinutData";

export class HostData {
  introduction: string;
  eliteHost = false;
  eliteHostSince: string;
  airbnb: HostAirbnbData;
  bookingAccounts: HostBookingData[];
  // iglooHome: HostIglooHomeData;
  // theKeys: HostTheKeysData;
  // minut: HostMinutData;
  includeCommunityRecommendation: boolean;
  teammateCantChangeAction: boolean;
  paymentMethodSetup: boolean;
}
