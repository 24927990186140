import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {FirebaseUser} from '../../model/firebase/FirebaseUser';
import {Person} from '../../model/Person';
import {CoHostInCharge, Property} from '../../model/Property';
import {PropertyOwner} from '../../model/secondary/PropertyOwner';
import {SnackbarMessage} from '../../model/snackbar/SnackbarMessage';
import {SnackbarService} from './SnackbarService';
import {PersonService} from './YaagoServices/PersonService';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DocType} from "../../model/modeldto/YaagoDocument";
import {PropertyBooking, TeammateInCharge} from "../../model/PropertyBooking";
import {PropertyForCalendarDto} from "../../model/PropertyForCalendarDto";
import {BookingGuestData} from "../../model/secondary/BookingGuestData";
import {Corporate} from "../../model/Corporate";
import {Permissions} from "../../model/PlatformRole";

@Injectable({ providedIn: 'root' })
export class UtilsService {
  constructor(private translateService: TranslateService,
              private snackbarService: SnackbarService,
              private personService: PersonService,
              private sanitizer: DomSanitizer) {
  }


  public readonly Style = {
    base: [
      "color: #fff",
      "background-color: #444",
      "padding: 2px 4px",
      "border-radius: 2px"
    ],
    warning: [
      "color: #eee",
      "background-color: red"
    ],
    success: [
      "background-color: green"
    ]
  }

  private readonly yaagoVersionNumber = '1.1.02';

  getVesion() {
    var pjson = require('../../../../package.json');
    // console.log(pjson.version);
    return pjson.version;
  }

  public hasPendingEmailValidation(corporate: Corporate) {
    if (!corporate || !corporate.postmarkEmailValidations || corporate.postmarkEmailValidations.length === 0) {
      return false;
    }
    return corporate?.postmarkEmailValidations?.findIndex(item => !item.confirmed) > -1;
  }

  public getLanguageNameFromCode(code: string): string {
    return this.translateService.instant('y0-language.' + code);
  }
  public manageCorporateColors(corporate: Corporate) {
    if (corporate != null) {
      if (corporate.backOfficePrimaryColor) {
        document.documentElement.style.setProperty('--dunnessant-color', corporate.backOfficePrimaryColor);
      }
      if (corporate.backOfficeForgetMeNotColor) {
        document.documentElement.style.setProperty('--forgetMeNot-color', corporate.backOfficeForgetMeNotColor);
      }
      if (corporate.backOfficeGreenVogueColor) {
        document.documentElement.style.setProperty('--greenVogue-color', corporate.backOfficeGreenVogueColor);
      }
      if (corporate.backOfficeCatskillColor) {
        document.documentElement.style.setProperty('--catskill-color', corporate.backOfficeCatskillColor);
      }

    }
  }

  public readonly yaagoTranslationLanguages = ["fr", "en", "es", "it","de"];
  public readonly yaagoEuropeanCountries = ["Spain", "Belgium", "Germany", "Italy"];

  readonly mapFlagNonStatic: Map<string, string> = new Map([
      ['English', '../../../../assets/flags/english-flag.png'],
      ['en', '../../../../assets/flags/english-flag.png'],
      ['Français',  '../../../../assets/flags/french-flag.png'],
      ['fr',  '../../../../assets/flags/french-flag.png'],
      ['ch',  '../../../../assets/flags/ch.svg'],
      ['il',  '../../../../assets/flags/il.svg'],
      ['no',  '../../../../assets/flags/norwegian-flag.png'],
      ['Español', '../../../../assets/flags/spanish-flag.png'],
      ['es', '../../../../assets/flags/spanish-flag.png'],
      ['Italian', '../../../../assets/flags/italian-flag.png'],
      ['it', '../../../../assets/flags/italian-flag.png'],
      ['Deutsch', '../../../../assets/flags/deutsch-flag.png'],
      ['de', '../../../../assets/flags/deutsch-flag.png'],
      ['Portuguese', '../../../../assets/flags/portuguese-flag.png'],
      ['pt', '../../../../assets/flags/portuguese-flag.png'],
      ['中文', '../../../../assets/flags/china-flag.jpg'],
      ['zh', '../../../../assets/flags/china-flag.jpg'],
      ['Magyar', '../../../../assets/flags/hungary-flag.jpg'],
      ['hu', '../../../../assets/flags/hungary-flag.jpg'],
      ['Polski', '../../../../assets/flags/poland-flag.jpg'],
      ['pl', '../../../../assets/flags/poland-flag.jpg'],
      ['ru', '../../../../assets/flags/russia-flag.jpg'],
      ['is', '../../../../assets/flags/iceland.png'],
      ['Dansk', '../../../../assets/flags/denmark-flag.png'],
      ['da', '../../../../assets/flags/denmark-flag.png'],
      ['dk', '../../../../assets/flags/denmark-flag.png'],
      ['Norsk', '../../../../assets/flags/norway-flag.png'],
      ['no', '../../../../assets/flags/norway-flag.png'],
      ['Svenska', '../../../../assets/flags/sweden-flag.png'],
      ['sv', '../../../../assets/flags/sweden-flag.png'],
      ['tr', '../../../../assets/flags/Turkey.svg'],
      ['nl', '../../../../assets/flags/nederland.jpeg'],
      ['ja', '../../../../assets/flags/japan-flag.jpg'],
      ['us', '../../../../assets/flags/american-flag.png'],
      ['br', '../../../../assets/flags/brazil.png'],
      ['ca', '../../../../assets/flags/canadian.jpg'],
      ['Русский', '../../../../assets/flags/russia-flag.jpg']
    ]
  );

  static readonly mapFlag: Map<string, string> = new Map([
      ['English', '../../../../assets/flags/english-flag.png'],
      ['en', '../../../../assets/flags/english-flag.png'],
      ['da', '../../../../assets/flags/denmark.png'],
      ['dk', '../../../../assets/flags/denmark.png'],
      ['Français',  '../../../../assets/flags/french-flag.png'],
      ['fr',  '../../../../assets/flags/french-flag.png'],
      ['no',  '../../../../assets/flags/norwegian-flag.png'],
      ['Español', '../../../../assets/flags/spanish-flag.png'],
      ['es', '../../../../assets/flags/spanish-flag.png'],
      ['Italian', '../../../../assets/flags/italian-flag.png'],
      ['Italiano', '../../../../assets/flags/italian-flag.png'],
      ['it', '../../../../assets/flags/italian-flag.png'],
      ['Deutsch', '../../../../assets/flags/deutsch-flag.png'],
      ['de', '../../../../assets/flags/deutsch-flag.png'],
      ['Portuguese', '../../../../assets/flags/portuguese-flag.png'],
      ['pt', '../../../../assets/flags/portuguese-flag.png'],
      ['中文', '../../../../assets/flags/china-flag.jpg'],
      ['zh', '../../../../assets/flags/china-flag.jpg'],
      ['Magyar', '../../../../assets/flags/hungary-flag.jpg'],
      ['hu', '../../../../assets/flags/hungary-flag.jpg'],
      ['Polski', '../../../../assets/flags/poland-flag.jpg'],
      ['pl', '../../../../assets/flags/poland-flag.jpg'],
      ['ru', '../../../../assets/flags/russia-flag.jpg'],
      ['Русский', '../../../../assets/flags/russia-flag.jpg'],
      ['ja', '../../../../assets/flags/japan-flag.jpg'],
      ['us', '../../../../assets/flags/american-flag.png'],
      ['br', '../../../../assets/flags/brazil.png'],
      ['nl', '../../../../assets/flags/nederland.jpeg'],
      ['tr', '../../../../assets/flags/Turkey.svg'],
      ['sv', '../../../../assets/flags/sweden.png'],
      ['ko', '../../../../assets/flags/korean.png'],
      ['ro', '../../../../assets/flags/romanian.png'],
      ['vi', '../../../../assets/flags/vietnam.png'],
      ['ca', '../../../../assets/flags/canadian.jpg'],
      ['cs', '../../../../assets/flags/cs.png'],
      ['is', '../../../../assets/flags/iceland.png'],
    ]
  );
  static getFullDate(date: string) {
    return moment(date).format('LLLL').substring(0, moment(date).format('LLLL').length );
  }
  getFullDate(date: string) {
    moment.locale(this.translateService.currentLang);
    return moment(date).format('LLLL').substring(0, moment(date).format('LLLL').length );
  }

  getFullDateNoTimeZone(date: string) {
    moment.locale(this.translateService.currentLang);
    return moment.utc(date).format('LLLL').substring(0, moment(date).format('LLLL').length );
  }

  getFullDateWihtoutTime(date: string) {
    moment.locale(this.translateService.currentLang);
    return moment(date).format('LL').substring(0, moment(date).format('LL').length );
  }

  getFullFullDateWihtoutTime(date: string) {
    return moment(date).format('LLLL').substring(0, moment(date).format('LLLL').length - 6 );
  }

  getLocalTime(date: string) {
    //return moment.utc(date , 'HH:mm').local().format('HH:mm')
    return moment(date , 'HH:mm').format("HH:mm"); //.local().format('HH:mm')
  }

  getLocalTimeFromDate(date: string) {
    return moment(date).local().format('HH:mm')
  }

  getMonthYear(date: string) {
    return moment(date).format('MMM YYYY');
  }
  static formatDate(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      ret = moment(date).format('DD/MM/YYYY - h:mm:ss A');
    }
    return ret;
  }

  static openInNewTabV1(link: string) {
    const a = document.createElement("a");
    a.setAttribute('href', link);
    a.setAttribute('target', '_blank');
    a.click();
    document.body.removeChild(a);
  }
  static openInNewTabV2(link: string) {
    setTimeout(() => {
      window.open(link, '_blank');
    }, 100);

  }

  static getInvitationPhoto(person: Person) {
    if (person.pictureUrl != null && person.pictureUrl !== '') {
      return person.pictureUrl;
    } else if (person.host != null) {
      if (person.host.airbnb != null && person.host.airbnb.pictureUrl != null) {
        return person.host.airbnb.pictureUrl;
      } else if (person.host.bookingAccounts != null) {
        return person.host.bookingAccounts[0].pictureUrl;
      } else {
        return  'assets/icons/guest-card/icon-guest.svg';
      }
    } else {
      return  'assets/icons/guest-card/icon-guest.svg';
    }
  }


  private readonly EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static getLanguageFlag(code: string) {
    return this.mapFlag.get(code);
  }

  isDesktop(windowWidth: number): boolean {
    return windowWidth >= 1024;
  }
  isTablet(windowWidth: number): boolean {
    return windowWidth < 1024 && windowWidth >= 768;
  }
  isMobile(windowWidth: number): boolean {
    return windowWidth < 768;
  }
  isEmail(email: string) {
    if (email) {
      email = email.toLowerCase();
    }
    return this.EMAIL_REGEX.test(email);
  }

  diffDays(date: string) {
    return moment(date).diff(moment().startOf('days'), 'days');
  }
  absDiffDays(date: string) {
    return Math.abs(moment(date).diff(moment().startOf('days'), 'days'));
  }

  pastDiff(date: string) {
    let ret = '';
    moment.locale(this.translateService.currentLang);
    if (moment().diff(moment(date), 'hours') < 1) {
      ret = this.translateService.instant('y0-general.now');
    } else if (moment().diff(moment(date), 'hours') < 24) {
      ret = this.translateService.instant('y0-general.last') + ' ' + moment().diff(moment(date), 'hours') + ' '  + this.translateService.instant('y0-general.hours');
    } else {
      ret = this.translateService.instant('y0-general.last') + ' ' + moment().diff(moment(date).startOf('days'), 'days') + ' ' +  this.translateService.instant('y0-general.days');
      if (moment().diff(moment(date).startOf('days'), 'days') < 2) {
        ret = this.translateService.instant('y0-general.last') + ' ' + moment().diff(moment(date).startOf('days'), 'days') + ' ' + this.translateService.instant('y0-general.days');
      }
    }
    return ret;
  }

  diff(date: string) {
    let ret = '';
    moment.locale(this.translateService.currentLang);
    if (moment(date).diff(moment(), 'hours') < 0) {
      ret = this.translateService.instant('y0-general.today');
    } else if (moment(date).diff(moment(), 'hours') < 24) {
      ret = this.translateService.instant('y0-general.tomorrow');
    } else {
        ret = this.translateService.instant('y0-general.in') + ' ' + moment(date).diff(moment().startOf('days'), 'days') + ' ' +  this.translateService.instant('y0-general.days');
      if (moment(date).diff(moment().startOf('days'), 'days') < 2) {
        ret = this.translateService.instant('y0-general.in') + ' ' + moment(date).diff(moment().startOf('days'), 'days') + ' ' + this.translateService.instant('y0-general.days');
      }
    }
    return ret;
  }

  diffbetween(date1: string, date2: string) {
    let ret = '';
    moment.locale(this.translateService.currentLang);
    if (moment(date1).diff(moment(date2), 'hours') < 0) {
      ret = this.translateService.instant('y0-general.today');
    } else if (moment(date1).diff(moment(date2), 'hours') < 24) {
      ret = this.translateService.instant('y0-general.tomorrow');
    } else {
      ret = this.translateService.instant('y0-general.in') + ' ' + moment(date1).diff(moment(date2).startOf('days'), 'days') + ' ' +  this.translateService.instant('y0-general.days');
      if (moment(date1).diff(moment(date2).startOf('days'), 'days') < 2) {
        ret = this.translateService.instant('y0-general.in') + ' ' + moment(date1).diff(moment(date2).startOf('days'), 'days') + ' ' + this.translateService.instant('y0-general.days');
      }
    }
    return ret;
  }

  versionNumber(): string {
    return this.yaagoVersionNumber;
  }
  formatTime(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    /*let ret = '';
    if (date != null && date !== '') {
      ret = moment(date).format('DD/MM/YYYY - h:mm:ss A');
    }
    return ret;*/
  }
  isTeamMate(property: Property): boolean {
    let ret = false;
    if (property != null && this.personService.getCurrentUser() != null) {
      if (property.teammateIds != null) {
        property.teammateIds.forEach(id => {
          if (id === this.personService.getCurrentUser().id) {
            ret = true;
          }
        });
      }
    }
    return ret;
  }

  isTeamMateForCalendar(property: PropertyForCalendarDto): boolean {
    let ret = false;
    if (property != null && this.personService.getCurrentUser() != null) {
      if (property.teammateIds != null) {
        property.teammateIds.forEach(id => {
          if (id === this.personService.getCurrentUser().id) {
            ret = true;
          }
        });
      }
    }
    return ret;
  }

  decryptDeferral(defrealNumber: string, before: boolean) {
    if (defrealNumber) {
      const deferrals = defrealNumber.match(/(\d+)/);
      const deferralNumber = parseFloat(deferrals[0]);
      if (deferrals && deferrals.length > 0) {
        if (deferralNumber === 0) {
          return this.translateService.instant('y0-general.immediately');
        } else if (deferralNumber >= 24) {
          const calculedDeferal = deferralNumber / 24;
          if (before) {
            return calculedDeferal === 1
              ? calculedDeferal + ' ' + this.translateService.instant('y0-general.daysBefore')
              : calculedDeferal + ' ' + this.translateService.instant('y0-general.daysBefore');
          } else {
            return calculedDeferal === 1
              ? calculedDeferal + ' ' + this.translateService.instant('y0-general.daysAfter')
              : calculedDeferal + ' ' + this.translateService.instant('y0-general.daysAfter');
          }
        } else {
          return deferralNumber + ' ' + this.translateService.instant('y0-general.hours');
        }
      }
    }
  }

  isTeammember(property: Property): boolean {
    let ret = false;
    if (property != null && this.personService.getCurrentUser() != null) {
      if (property.owners != null) {
        property.owners.forEach(h => {
          if (h.hostId === this.personService.getCurrentUser().id && (h.hostType === 'TEAM_MEMBER' || h.hostType === 'Teammate')) {
            ret = true;
          }
        });
      }
    }
    return ret;
  }

  isCoHost(property: Property): boolean {
    let ret = false;
    if (property != null && this.personService.getCurrentUser() != null) {
      if (property.owners != null) {
        property.owners.forEach(h => {
          if (h.hostId === this.personService.getCurrentUser().id && h.hostType === 'Co-Host') {
            ret = true;
          }
        });
      }
    }
    return ret;
  }

  amIYaagoOrInfineUser(): boolean {
    const me: Person = this.personService.getCurrentUser();
    return me != null && (me.email.endsWith('@yaago.com') || me.email.endsWith('yaago.fr') || me.email.endsWith('infine.io') || me.beta);
  }

  getCoHosts(property: Property): Person[] {
    const theHost: PropertyOwner = property.owners.find( h => h.hostType === 'Host' || h.hostType === 'Host-Pro');
    return property.hosts ?
      property.hosts.filter(h => h.id !== theHost.hostId && h.email !== 'all@yaago.com') : [];
  }

  isHost(property: Property): boolean {
    let ret = false;
    if (property != null && this.personService.getCurrentUser() != null) {
      if (property.owners != null) {
        property.owners.forEach(h => {
          if (h.hostId === this.personService.getCurrentUser().id && (h.hostType === 'Host' || h.hostType === 'Host-Pro') ) {
            ret = true;
          }
        });
      }
    }
    return ret;
  }

  getPropertyHost(property: Property): PropertyOwner {
    if (property?.owners != null) {
      return property.owners.find(po => po.hostType === 'Host');
    } else {
      return null;
    }
  }

  hasHostPaid(property: Property): boolean {
    let ret = true;
    if (property != null && this.personService.getCurrentUser() != null) {
      if (property.owners != null) {
        property.owners.forEach(h => {
          if (h.hostId === this.personService.getCurrentUser().id && (h.hostType === 'Host' || h.hostType === 'Host-Pro') ) {
            ret = !h.trialPeriodOver;
          }
        });
      }
    }
    return ret;
  }

  yaago_v2_removeFromIterable(it: any[], value: any): any[] {
    let newIt: any[] = [];
    it.forEach( item => {
      if (item !== value) {
        newIt.push(item);
      } else {
      }
    });
    return newIt;
  }

  newRemoveFromIterable(it: any[], value: any): any[] {
    let newIt: any[] = [];
    it.forEach( item => {
      if (item !== value) {
        newIt.push(item);
      } else {
      }
    });
    return newIt;
  }

  removeFromIterable(it: any[], value: any): number {
    let newIt: any[] = [];
    it.forEach( item => {
      if (item !== value) {
        newIt.push(item);
      }
    });
    it = newIt;
    return 0;

  }
  nbActiveActions(property: Property) {
    if (property.actions != null) {
      return property.actions.filter(a => a.enabled === true || a.enabledSms === true).length;
    } else {
      return 0;
    }
  }
  isPlatform(property, platformType: string) {
    return property &&
      (
        (property.calendars && property.calendars.length > 0 && property.calendars.findIndex(item => item.platformType.toLowerCase() === platformType.toLowerCase()) > -1)
        || (property.platformNames && property.platformNames.length > 0 && property.platformNames.findIndex(item => item.toLowerCase() === platformType.toLowerCase()) > -1)
        || (property.otaListings && property.otaListings.length > 0 && property.otaListings.findIndex(item => item.platform.toLowerCase() === platformType.toLowerCase()) > -1)
      );

  }

  formatTimeSpent(numberOfMinute: number): string {
    let returnDisplay = '';
    const counterH = Math.floor(numberOfMinute / 60);
    const counterM = numberOfMinute % 60;
    if (counterH > 0) {
      returnDisplay += counterH + ' h ';
    }
    if (counterM > 0) {
      returnDisplay += counterM  + ' mn';
    }
    return returnDisplay;
  }

  formatDate(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format('dddd DD/MM/YYYY @ HH:mm:ss');
    }
    return ret;
  }



  formatDateWithoutDay(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format('DD/MM/YYYY @ HH:mm');
    }
    return ret;
  }

  formatDateWithoutDayWithoutYear(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format('DD/MM@HH:mm');
    }
    return ret;
  }

  formatDateOnly(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format('dddd DD/MM/YYYY');
    }
    return ret;
  }

  formatDateOnlyShort(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format('DD/MM/YYYY');
    }
    return ret;
  }

  formatDateTime(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format('DD/MM/YYYY HH:mm');
    }
    return ret;
  }

  formatTimeOnly(time: string) {
    return moment(time, 'HH:mm:ss').format('HH:mm');
  }

  customFormatDate(date: string, format: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format(format);
    }
    return ret;
  }

  formatFullDateOnly(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format('dddd DD MMMM YYYY');
    }
    return ret;
  }

  formatTime2(date: string): string {
    try {
      // TO DO NICE TO HAVE FORMAT US format
      return moment(date.substring(0, date.lastIndexOf('['))).format('dddd D/M/YYYY @ HH:mm:ss');
    } catch (e) {
      return '';
    }
  }

  handleYaagoErrorCode(error: HttpErrorResponse) {
    if (error.status === 400) {
      console.log('errorCode', error.headers.get('yaago-error-code'));
      if (error.headers.get('yaago-error-code') != null && error.headers.get('yaago-error-code').split(',').length > 1) {
        let messageErreur = '';
        error.headers.get('yaago-error-code').split(',').forEach(err => {
          messageErreur += this.translateService.instant('yaago-error-code.' + err.trim()) + ' ';
        });
        this.snackbarService.push(new SnackbarMessage(messageErreur.trim(), 'error'));
      } else {
        this.snackbarService.push(new SnackbarMessage(
          this.translateService.instant('yaago-error-code.' + error.headers.get('yaago-error-code')), 'error'));
        }
    } else {
      this.snackbarService.push(new SnackbarMessage(error.message, 'error'));
    }
  }

  returnYaagoErrorCode(error: HttpErrorResponse): string {
    if (error.status === 400) {
      console.log('errorCode', error.headers.get('yaago-error-code'));
      if (error.headers.get('yaago-error-code') != null && error.headers.get('yaago-error-code').split(',').length > 1) {
        let messageErreur = '';
        error.headers.get('yaago-error-code').split(',').forEach(err => {
          messageErreur += this.translateService.instant('yaago-error-code.' + err.trim()) + ' ';
        });
        return messageErreur.trim().replace('yaago-error-code', '');
      } else {
        return 'yaago-error-code.' + error.headers.get('yaago-error-code');
      }
    } else {
      return error.message;
    }
  }
  isGoogleUser(firebaseUser: FirebaseUser): boolean {
    return this.isProviderUser(firebaseUser,  'google.com');
  }
  isFacebookUser(firebaseUser: FirebaseUser): boolean {
    return this.isProviderUser(firebaseUser,  'facebook');
  }
  isProviderUser(firebaseUser: FirebaseUser, providerName: string): boolean {
    if (firebaseUser.providerData.length > 0) {
      if (firebaseUser.providerData[0].providerId === providerName ) {
        return true;
      }
    } else {
      return false;
    }
  }
  displayDistance(distance: number) {
    // console.log(distance);
    if (distance > 0) {
      return distance.toFixed(2) + ' km';
    } else {
      return (distance * 100) + ' m';
    }
  }

  yaagoFormatDateStringToTime(date: string) {
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format('HH:mm');
    }
    return ret;
  }

  getFirstLetterTeammate(host: TeammateInCharge) {
    if (!host) {
      return '';
    }
    if (host.firstname) {
      return host.firstname.substr(0, 1).toUpperCase();
    }
    if (host.lastname) {
      return host.lastname.substr(0, 1).toUpperCase();
    }
    if (host.email) {
      return host.email.substr(0, 1).toUpperCase();
    }
  }
  getFirstLetter(host: Person | CoHostInCharge) {
    if (!host) {
      return '';
    }
    if (host.firstName) {
      return host.firstName.substr(0, 1).toUpperCase();
    }
    if (host.lastName) {
      return host.lastName.substr(0, 1).toUpperCase();
    }
    if (host.email) {
      return host.email.substr(0, 1).toUpperCase();
    }
  }

  getFirstLetterString(firstName: string, lastName: string, email: string) {
    if (firstName) {
      return firstName.substr(0, 1);
    }
    if (lastName) {
      return lastName.substr(0, 1);
    }
    if (email) {
      return email.substr(0, 1);
    } else {
      return "U";
    }
  }
  groupByKey(array, key) {
    // Return the end result
    if (array) {
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, []); // empty object is the initial value for result object
    }
  }

  groupByKey2(array, key, key2) {
    // Return the end result
    if (array) {
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object

        if (currentValue[key2] !=null && currentValue[key2] !== '') {
          (result[currentValue[key2]] = result[currentValue[key2]] || []).push(
            currentValue
          );
        } else {

          (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
          );
        }
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, []); // empty object is the initial value for result object
    }
  }

  videoUrlType(url: string): DocType {
    if (url == null || url.trim() === '') {
      return null;
    }
    const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const vimeoRegex = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
    const dailyMotionRegex = /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/i;

    const matchYoutube = url.match(youtubeRegExp);
    const matchVimeo = url.match(vimeoRegex);
    const matchDailyMotion = url.match(dailyMotionRegex);

    if (matchYoutube && matchYoutube[2].length === 11) {
      return DocType.YOUTUBE;
    }

    if (matchVimeo && matchVimeo[1].length > 0) {
      return DocType.VIMEO;
    }

    if (matchDailyMotion && matchDailyMotion[2].length > 0) {
      return DocType.DAILYMOTION;
    }


    return DocType.EXTERNAL_VIDEO;
  }
  sanitizeVideoUrl(url: string): SafeResourceUrl {
    if (url != null && url.trim() !== '') {
      /*const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*!/;
      const match = url.match(regExp);*/
      const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const vimeoRegex = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
      const dailyMotionRegex = /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/i;

      const matchYoutube = url.match(youtubeRegExp);
      const matchVimeo = url.match(vimeoRegex);
      const matchDailyMotion = url.match(dailyMotionRegex);

      if (matchYoutube && matchYoutube[2].length === 11) {
        url = url.replace(url, 'https://www.youtube.com/embed/' + matchYoutube[2] + "?allowfullscreen");

      }

      if (matchVimeo && matchVimeo[1].length > 0) {
        url = url.replace(url, 'https://player.vimeo.com/video/' + matchVimeo[1]);
      }

      if (matchDailyMotion && matchDailyMotion[2].length > 0) {
        url = url.replace(url, 'https://www.dailymotion.com/embed/video/' + matchDailyMotion[2]);
      }

      // if (url.startsWith('https://youtu.be/')) {
      //   url = url.replace('https://youtu.be/', 'https://www.youtube.com/embed/');
      // } else {
      //   url = url.replace('watch?v=', 'embed/');
      // }
      // this.currentTranslation.video = url;
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  getTheKeysCode(propertyBooking: PropertyBooking): string {
    let retCode = '';
    if (propertyBooking.theKeysAccesses != null) {
      propertyBooking.theKeysAccesses.forEach( access => {
        if (access.accessoryType === 'Digicode') {
          retCode = access.code;
        }
      });
      return retCode;
    }
  }

  getTheKeysLink(propertyBooking: PropertyBooking): string {
    let retCode = '';
    if (propertyBooking.theKeysAccesses != null) {
      propertyBooking.theKeysAccesses.forEach( access => {
        if (access.accessoryType === 'Gateway') {
          retCode = access.code;
        }
      });
      return retCode;
    }
  }

  titleFromLink(url: string) {
    console.log('URL', url);
    return fetch(url)
      .then((response) => response.text())
      .then((html) => {
        const doc = new DOMParser().parseFromString(html, "text/html");
        const title = doc.querySelectorAll('title')[0];
        return title.innerText;
      });
  }

  isCotton(myProfile) {
    return myProfile.email.indexOf('cotoon') >= 0;
  }

  getCleanTextToRead(desc: string) {
    if (!desc) {
      return;
    }
    let textToSpeech = desc.replace(/<[^>]*>/g, ' ');
    textToSpeech = textToSpeech.replace(/&nbsp;/gi, ' ');
    // textToSpeech = textToSpeech.replace(/^https?:\/\/.*[\r\n]*/g, ' ');
    return textToSpeech;
  }
  static getNonTranslatedLanguageName( languageCode: string) {
    switch (languageCode) {
      case 'fr':
        return 'Français';
      case 'en':
        return 'English';
      case 'de':
        return 'Deutsch';
      case 'es':
        return 'Español';
      case 'it':
        return 'Italiano';
      case 'nl':
        return 'Nederlands';
      case 'pt':
        return 'português';
      case 'da':
        return 'Dansk';
      case 'no':
        return 'Norsk';
      case 'ru':
        return 'русский';
      case 'pl':
        return 'Polskie';
      case 'sv':
        return 'Svenska';
      case 'tr':
        return 'Türk';
      case 'zh':
        return '中国人';
        case 'ja':
          return '日本';
    }
  }

  static getNonTranslatedLanguageNameChanged( languageCode: string) {
    switch (languageCode) {
      case 'fr':
        return 'Français';
      case 'en':
        return 'Anglais';
      case 'de':
        return 'Allemand';
      case 'es':
        return 'Espagnol';
      case 'it':
        return 'Italien';
      case 'nl':
        return 'Hollandais';
      case 'pt':
        return 'Portuguais';
      case 'da':
        return 'Danois';
      case 'no':
        return 'Norvégien';
      case 'ru':
        return 'Russe';
      case 'pl':
        return 'Polonais';
      case 'sv':
        return 'Svenska';
      case 'tr':
        return 'Turque';
      case 'zh':
        return 'Chinois';
        case 'ja':
          return 'Japanese';
    }
  }

  static readonly languageArray: string[] = ['fr', 'en', 'de', 'es', 'it', 'nl', 'da', 'pt', 'ja',  'no', 'pl',  'ru', 'sv', 'tr', 'zh'];

  static readonly languageArrayForWebApp: string[] = ['fr', 'en', 'es', 'it', 'de'];

  getGuestFirstLetter(guest: BookingGuestData) {
    if (!guest) {
      return '';
    }
    if (guest.firstName) {
      return guest.firstName.substr(0, 1).toUpperCase();
    }
    if (guest.lastName) {
      return guest.lastName.substr(0, 1).toUpperCase();
    }
    if (guest.email) {
      return guest.email.substr(0, 1).toUpperCase();
    }
    return '';
  }

  hasPermission(requiredPermission: Permissions | string, corporateId: string, requiredModule?: string): boolean {
    const profile = this.personService.getCurrentUser();
    if (!this.hasModuleTag(requiredModule, corporateId)) {
      return false;
    }
    if (profile.corporate) {
      if (profile.corporate?.corporateOwnerId === profile.id && requiredPermission === Permissions.SETTINGS_PLATFORM_CONNECTIONS) {
        return profile.subscriptionName !== 'success';
      }
      if (profile.corporate?.corporateOwnerId === profile.id) {
        if (requiredPermission === Permissions.SETTINGS_ROLE_ONLY_MYTEAM || requiredPermission === Permissions.BOOKING_CANT_SEE_INTERNAL_NOTE || requiredPermission === Permissions.TASKS_CANT_SEE_INTERNAL_NOTE) {
          return false;
        } else {
          return true;
        }
      }
    }
    if (profile.motherCorporate?.corporateOwnerId === profile.id) {
      return true;
    }
    if (!requiredPermission) { // the case we are asking only for module not permission
      return true;
    }
    if (!profile || !profile.corporatePermissions || profile.corporatePermissions.length === 0) {
      return false;
    }
    let currentPermissionList = [];
    if (corporateId) {
      currentPermissionList = profile.corporatePermissions?.find(item => item.corporateId === corporateId)?.permissions;
    } else if (profile.corporateId) {
      currentPermissionList = profile.corporatePermissions?.find(item => item.corporateId === profile.corporateId)?.permissions;
    } else { // this case is not realistic

      currentPermissionList = profile.corporatePermissions[0].permissions;
    }
    return currentPermissionList.includes(requiredPermission);
  }



  isCorporateOwner(corporateId?: string) {
    const profile = this.personService.getCurrentUser();
    return profile.corporate?.corporateOwnerId === profile.id || profile.motherCorporate?.corporateOwnerId === profile.id;
  }

  hasModuleTag(requiredModule: string, corporateId: string) {
    if (!requiredModule) {
      return true;
    }
    const profile = this.personService.getCurrentUser();
    if (!profile.corporatePermissions || profile.corporatePermissions.length === 0) {
      return false;
    }
    if (corporateId) {
      const cp = profile.corporatePermissions.find(item => item.corporateId === corporateId);
      return cp && cp.modulesTags && cp.modulesTags.length > 0  && cp.modulesTags.includes(requiredModule);
    }

    // if no corporateId specified we gonna look for at least one
    const found = profile.corporatePermissions.findIndex(item => item.modulesTags && item.modulesTags.length > 0  && item.modulesTags.includes(requiredModule));
    return found > -1;
  }

  consoleLog (text, extra = []) {
    let style = this.Style.base.join(';') + ';';
    style += extra.join(';'); // Add any additional styles
    console.log(`%c${text}`, style);
  }

  getLanguageFlag(code: string) {
    return this.mapFlagNonStatic.get(code);
  }

  // function to calculate the difference in days between two dates

  takeOtaOnboardingMeeting() {
    window.open("https://meetings.hubspot.com/david-duval/connexions-plateformes-yaago", "_blank");
  }

  htmlToText(html: string) {

    if (!html) {
      return html;
    }

    let without_html = html.replace(/<(?:.|\n)*?>/gm, ' ');

    return without_html;

  }

  getDateStringWithoutTimeZone(date: string) {
    return date.substring(0, 16).replace('T', ' @ ');
  }


}

