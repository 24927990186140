import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpService} from '../HttpService';
import {TokenService} from '../TokenService';
import {ConfService} from '../../services/ConfService';
import {BehaviorSubject, Observable} from 'rxjs';
import {Tags} from '../../../model/Tags';
import {flatMap} from 'rxjs/operators';
import {RecommendationCommentDto} from '../../../model/modeldto/RecommendationCommentDto';
import {Recommendation} from '../../../model/Recommendation';
import {CommentDto} from '../../../model/modeldto/CommentDto';
import {RecommendationSearchRequestDto} from '../../../model/modeldto/RecommendationSearchRequestDto';
import {Property} from '../../../model/Property';
import {RecommendationCategory} from '../../../model/secondary/RecommendationCategory';
import {RecommendationSearchResponse} from '../../../model/secondary/RecommendationSearchResponse';
import {Comment} from '../../../model/secondary/Comment';
import {Poi} from "../../../model/secondary/Poi";
import {RecommendationSearch} from "../../../model/recommendation/RecommendationSearch";
import {RecommendationPage} from "../../../model/recommendation/RecommendationPage";

@Injectable({ providedIn: 'root' })
export class RecommendationService {

  isCreating$ = new BehaviorSubject({openDialog: false, idToLoad: null, poi: null, from: null, googlePlace: null});
  cardDetails$  = new BehaviorSubject(null);
  miniCardDetails$  = new BehaviorSubject(null);
  seeAllReviews = new BehaviorSubject({reviews: null, title: null});
  refreshRecommendations$ = new BehaviorSubject(false);
  centerMap$ = new BehaviorSubject({x: null, y: null});
  isDeleting$ = new BehaviorSubject({openDialog: false, recToDelete: null});

  constructor(private http: HttpClient,
              private httpService: HttpService,
              private tokenService: TokenService,
              private confService: ConfService) {
  }

  startCreate(toLoad: string = null, position: Poi = null, cameFrom = null, googlePlace = null) {
    this.isCreating$.next({openDialog: true, idToLoad: toLoad, poi: position, from: cameFrom, googlePlace});
  }

  endCreate() {
    this.isCreating$.next({openDialog: false, idToLoad: null, poi: null, from: null, googlePlace: null});
  }

  getStatusOfCreate(): Observable<any> {
    return this.isCreating$.asObservable();
  }

  openCard(obj) {
    this.cardDetails$.next(obj);
  }

  closeCard() {
    this.cardDetails$.next(null);
  }

  getStatusOfCard(): Observable<any> {
    return this.cardDetails$.asObservable();
  }

  openMiniCard(obj) {
    this.miniCardDetails$.next(obj);
  }

  closeMiniCard() {
    this.miniCardDetails$.next(null);
  }

  getMiniCard(): Observable<any> {
    return this.miniCardDetails$.asObservable();
  }

  openReviews(reviews, title) {
    this.seeAllReviews.next({reviews, title});
  }

  closeReviews() {
    this.seeAllReviews.next({reviews: null, title: null});
  }

  startRefresh() {
    this.refreshRecommendations$.next(true);
  }

  getStatusOfRefresh() {
    return this.refreshRecommendations$.asObservable();
  }

  centerMainMap(position: Poi) {
    this.centerMap$.next(position);
  }
  getMapCentring() {
    return this.centerMap$.asObservable();
  }

  showDeletingConfirmation(recommendation) {
    this.isDeleting$.next({openDialog: true, recToDelete: recommendation});
  }
  hideDeletingConfirmation() {
    this.isDeleting$.next({openDialog: false, recToDelete: null});
  }
  getShowDeletionConfirmation() {
    return this.isDeleting$.asObservable();
  }

  getStatusOfReviews(): Observable<any> {
    return this.seeAllReviews.asObservable();
  }

  getTags(): Observable<Tags[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Tags[]>(this.confService.getUrlApiEndPoint() + 'api-v2/tags', this.httpService.getHeaders(to));
    }));
  }

  getCleanedGooglePhotos(placeId: string): Observable<string[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<string[]>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation/g-photos/' + placeId, this.httpService.getHeaders(to));
    }));
  }

  createRecommendation(recommendation: RecommendationCommentDto | Recommendation): Observable<Recommendation> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Recommendation>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation', recommendation, this.httpService.getHeaders(to));
    }));
  }

  updateRecommendation(recommendation: RecommendationCommentDto | Recommendation): Observable<Recommendation> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Recommendation>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation', recommendation, this.httpService.getHeaders(to));
    }));
  }

  createUpdateComment(recommendation: RecommendationCommentDto | Recommendation, comment: Comment): Observable<Recommendation> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Recommendation>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation/' + recommendation.id, comment, this.httpService.getHeaders(to));
    }));
  }

  loadRecommendationById(recommendationId): Observable<Recommendation> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Recommendation>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation/' + recommendationId, this.httpService.getHeaders(to));
    }));
  }

  deleteFromMyAddresses(recommendationId: string): Observable<Recommendation> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<Recommendation>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation/' + recommendationId, this.httpService.getHeaders(to));
    }));
  }

  deleteHostComment( recommendationId: string, propertyId: string): Observable<Recommendation> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('propertyId', propertyId);
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<Recommendation>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation/' + recommendationId, this.httpService.getOptionsWithParams(to, httpParams));
    }));
  }

  insertCommentToRecommendation(comment: CommentDto): Observable<Recommendation> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Recommendation>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation', comment, this.httpService.getHeaders(to));
    }));
  }

  getRecommendationByAddress(poiInfo: any): Observable<Recommendation> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Recommendation>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation/check-existing', poiInfo, this.httpService.getHeaders(to));
    }));
  }

  searchRecommendation(request: RecommendationSearchRequestDto): Observable<RecommendationSearchResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<RecommendationSearchResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation/search', request, this.httpService.getHeaders(to));
    }));
  }

  getMyRecommendationList(recommendationSearch: RecommendationSearch): Observable<RecommendationPage> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<RecommendationPage>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation/get-mine', recommendationSearch, this.httpService.getHeaders(to));
    }));
  }

  getCommentByRecommendationId(recommendationId: any): Observable<Comment> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Comment>(this.confService.getUrlApiEndPoint() + 'api-v2/recommendation/comment/' + recommendationId, this.httpService.getHeaders(to));
    }));
  }

  prepareMarkerV2(feature: any, property: Property, lat: number = 0, long: number = 0, isDesktopMode: boolean): string {
    let content = '';
    if (feature.recommendation != null) {
      content += '<style>';
      if (isDesktopMode) {
        content += '.card-container { display: flex; flex-flow: row; margin-top: 25px; margin-bottom: 25px; margin-right: 25px; margin-left: 25px; }';
        content += '.img-container { width: 96px; margin-right: 25px;}';
      } else {
        content += '.card-container { display: flex; flex-flow: column; margin-top: 15px; margin-bottom: 15px; margin-right: 15px; margin-left: 15px; }';
        content += '.img-container { width: 96px; margin-right: 15px; margin-bottom: 15px;}';
      }

      content += '.title { font-family: "Hind Madurai"; font-weight: 600; color: #107fde; font-size: 28px; letter-spacing: 0.67px; }';
      content += '.address { font-family: "Hind Madurai"; color: #001440; font-size: 16px; letter-spacing: 0.38px; margin-top: 8px; }';
      content += '.distance { font-family: "Hind Madurai"; color: #001440; font-weight: 500; font-size: 16px; letter-spacing: 0.38px; margin-top: 2px; }';
      content += '.rate { font-family: "Hind Madurai"; color: #001440; font-size: 16px; letter-spacing: 0.38px; margin-top: 10px; font-weight: normal; }';
      content += '.reviews { font-family: "Hind Madurai"; color: #f65446; font-size: 16px; letter-spacing: 0.29px; margin-top: 10px; font-weight: bold; }';
      content += '.rate-img { padding-right: 8px; }';
      content += '.bold { font-weight: 600; }';
      content += '.pointer { cursor: pointer }';
      if (isDesktopMode) {
        content += '.margin { margin-right: 16px; }';
      }
      content += '.icon-container { display: flex; flex-flow: row; align-items: center; }';
      content += '.direction { background-color: rgba(246, 84, 70, 0.08); border-radius: 6px; border: solid 1px #f65446; margin-top: 16px; width: 48px; height: 48px; display: flex; align-items: center; justify-content: center; }';
      content += '.direction2 { background-color: rgba(16, 127, 222, 0.08); border-radius: 6px; border: solid 1px #107fde; margin-top: 16px; width: 48px; height: 48px; display: flex; align-items: center; justify-content: center; }';
      content += '.get-direction { background-color: rgba(16, 127, 222, 0.08); border-radius: 6px; border: solid 1px #107fde; margin-top: 16px; height: 48px; display: flex; flex-flow: row; align-items: center; padding-left: 16px; padding-right: 16px; color: #107fde; font-family: "Barlow Semi Condensed"; font-weight: 600; font-size: 16px; }';
      content += '.icon-website { width: 19px; height: 19px; }';
      content += '.noPicture { background-color: #f0f0f0; border-radius: 16px; display: flex; flex-flow: row; justify-content: center; align-items: center; width: 96px; height: 96px; }';
      content += '.picture { width: 96px; height: 96px; object-fit: cover; border-radius: 16px; }';
      content += '.tag { text-transform: normal; margin-top: 16px; padding-left: 16px; padding-right: 16px; padding-top: 4px; padding-bottom: 4px; color: white; border-radius: 11px; box-shadow: 0 4px 12px 0 rgba(34, 181, 126, 0.39); background-color: #40d09a; }';
      content += '</style>';

      content += '<div class="card-container">';
      if (feature.recommendation.photos == null || feature.recommendation.photos.length === 0) {
        content += '    <div class="img-container">';
        content += '      <div class="noPicture"><img src="assets/icons/recommendation/noPicture.svg"></div>';
        content += '    </div>';
      } else {
        content += '    <div class="img-container">';
        content += '      <div class="noPicture"><img class="picture" src="' +  feature.recommendation.photos[0] +  '"></div>';
        content += '    </div>';
      }
      content += '  <div>';
      content += '  <div>';
      content += '    <p class="title">' + feature.recommendation.title + '</p>';
      content += '    <p class="address">' + feature.recommendation.fullAddress + '</p>';
      if (feature.recommendation.distance < 1) {
        content += '    <p class="distance">' + (feature.recommendation.distance * 1000).toFixed(0) + ' m distance</p>';
      } else {
        content += '    <p class="distance">' + (feature.recommendation.distance).toFixed(1) + ' km distance</p>';
      }
      content += '    <p class="rate bold"><img class="rate-img" src="assets/icons/recommendation/icon-rate.svg">' + feature.recommendation.ranking + '<span class="rate"> based on  </span><span class="reviews">' + (feature.recommendation.comments == null ? 0 : feature.recommendation.comments.length) + ' review(s)</span></p>';
      content += '  </div>';
      content += '  <div class="icon-container">';
      content += '    <div class="tag">' + feature.recommendation.category + '</div>';
      content += '  </div>';

      content += '  <div class="icon-container">';

      if (isDesktopMode) {
        content += '    <div class="pointer get-direction">';
      } else {
        content += '    <div class="pointer direction2">';
      }
      if (property) {
        content += '<a target="_blank" href="https://www.google.com/maps/dir/' + property.poi.y + ',' + property.poi.x + '/' + feature.recommendation.poi.y + ',' + feature.recommendation.poi.x + '" ><img class="icon-website margin" src="../../../../assets/icons/recommendation/icon-directions.svg"></a>';
      } else {
        content += '<a target="_blank" href="https://www.google.com/maps/dir/' + lat + ',' + long + '/' + feature.recommendation.poi.y + ',' + feature.recommendation.poi.x + '" ><img class="icon-website margin" src="../../../../assets/icons/recommendation/icon-directions.svg"></a>';
      }
      if (isDesktopMode) {
        content += '      Get Directions</div>';
      } else {
        content += '</div>';
      }
      content += '    <div  style="width: 16px"></div>';



      if (feature.recommendation.webSite) {
        content += '    <div class="pointer direction">';
        content += '    <a target="_blank" href="' + feature.recommendation.webSite + '">';
        content += '      <img src="../../../../assets/icons/recommendation/icon-website.svg" class="icon-website"></a></div>';
        content += '    <div  style="width: 16px"></div>';
      }
      if (feature.recommendation.phoneNumber) {
        content += '    <div  class="pointer direction">';
        content += '    <a href="tel:' + feature.recommendation.phoneNumber + '">';
        content += '      <img src="assets/icons/recommendation/icon-phone.svg" class="icon-website"></a></div>';
        content += '    <div  style="width: 16px"></div>';
      }
      content += '  </div>';

      content += '  </div>';
      content += '</div>';
    }
    // console.log(feature.recommendation);
    return content;
  }

  getClusterStyle() {
    return  [
      {
        textColor: 'white',
        url: './assets/icons/recommendation/marker-clustered.svg',
        height: 70,
        width: 70,
        textSize: 15
      },
      {
        textColor: 'white',
        url: './assets/icons/recommendation/marker-clustered.svg',
        height: 70,
        width: 70,
        textSize: 15
      },
      {
        textColor: 'white',
        url: './assets/icons/recommendation/marker-clustered.svg',
        height: 70,
        width: 70,
        textSize: 15
      },
      {
        textColor: 'white',
        url: './assets/icons/recommendation/marker-clustered.svg',
        height: 70,
        width: 70,
        textSize: 15
      },
      {
        textColor: 'white',
        url: './assets/icons/recommendation/marker-clustered.svg',
        height: 70,
        width: 70,
        textSize: 15
      }
    ];
  }


}
