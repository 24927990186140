import {Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {SnackbarService} from "../../../core/services/SnackbarService";
import {PersonService} from "../../../core/services/YaagoServices/PersonService";
import {SnackbarMessage} from "../../../model/snackbar/SnackbarMessage";
import {fromEvent, Subscription} from "rxjs";
import {UtilsService} from "../../../core/services/UtilsService";

@Directive({
  selector: '[appPermissionClaim]'
})
export class PermissionClaimDirective implements OnInit, OnDestroy {

  @Input() type: 'view' | 'click' = 'view';
  @Input() corporateId: string;
  @Input() requiredPermission: string;
  @Input() requiredModule: string;

  currentPermissionList: string[] = [];

  @HostBinding('style.display') display!: string;

  @HostListener('click', ['$event'])
  clickEvent(event) {
    if (this.type === 'click' && !this.currentPermissionList?.includes(this.requiredPermission)) {
      this.snackbar.push(new SnackbarMessage('role-management.not-enough-permission', 'error'));
      event.stopImmediatePropagation();
      event.preventDefault();
      event.stopPropagation();

      return false;
    }
    console.log('click listener passed');
  }

  constructor(public snackbar: SnackbarService,
              private personService: PersonService,
              private utilsService: UtilsService) {

    this.initPermissions();
  }

  ngOnInit() {
    if (this.type === 'view' && !this.utilsService.hasPermission(this.requiredPermission, this.corporateId, this.requiredModule)) {
     this.display = 'none';
    }
  }
  ngOnDestroy() {
  }

  initPermissions() {
    const profile = this.personService.getCurrentUser();
    if (!profile) {
      return;
    }
    if (this.corporateId) {
      this.currentPermissionList = profile.corporatePermissions?.find(item => item.corporateId === this.corporateId)?.permissions;
    } else if (profile.corporateId) {
      this.currentPermissionList = profile.corporatePermissions?.find(item => item.corporateId === profile.corporateId)?.permissions;
    }
    // console.log("THIS PERMISSION LIST", this.currentPermissionList);
    // console.log("THIS PROFILE", profile);
  }


}
