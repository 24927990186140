import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConfService {

  public getUrlApiEndPoint(): string {
    try {
      const url: string = window["backendUrl"];
      if (url == null || url.trim() === '') {
        return window.location.origin + '/';
      } else {
        return url;
      }
    } catch (exception) {
      console.log(exception);
      return window.location.origin + '/';
    }
  }

}
