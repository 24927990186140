
import { NgxMatDatetimePickerModule } from "@angular-material-components/datetime-picker";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MatRippleModule, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { QuillModule } from 'ngx-quill';
import { MyDateAdapter } from '../../model/secondary/MyDateAdapter';
import { CustomDatepickerHeaderBetaComponent } from '../calendar/manual-booking-beta/custom-datepicker-header-beta/custom-datepicker-header-beta.component';
import { CustomDatepickerHeaderComponent } from '../calendar/manual-booking/custom-datepicker-header/custom-datepicker-header.component';
import { ButtonComponent } from './components/button/button.component';
import { CarouselItemDirective } from './components/carousel/carousel-item.directive';
import { CarouselComponent, CarouselItemElement } from './components/carousel/carousel.component';
import { CircularStepperComponent } from './components/circular-stepper/circular-stepper.component';
import { ExternalVideoBoxComponent } from './components/external-video-box/external-video-box.component';
import { InviteCardComponent } from './components/invite-card/invite-card.component';
import { LocationPickerComponent } from './components/location-picker/location-picker.component';
import { NavTopMenuComponent } from './components/nav-top-menu/nav-top-menu.component';
import { PersonInfoComponent } from './components/person-info/person-info.component';
import { PropertyCalendarListComponent } from './components/property-calendar-list/property-calendar-list.component';
import { PropertyHeaderComponent } from './components/property-header/property-header.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { ServiceInfoComponent } from './components/service-info/service-info.component';
import { SharedFulladdressInputComponent } from './components/shared-fulladdress-input/shared-fulladdress-input.component';
import { SharedLanguageSliderComponent } from './components/shared-language-slider/shared-language-slider.component';
import { SharedPanHelpSimpleComponent } from './components/shared-pan-help-simple/shared-pan-help-simple.component';
import { SharedSnackbarFormodalComponent } from './components/shared-snackbar-formodal/shared-snackbar-formodal.component';
import { SharedToggleButtonComponent } from './components/shared-toggle-button/shared-toggle-button.component';
import { SharedUploadComponent } from './components/shared-upload/shared-upload.component';
import { SliderComponent } from './components/slider/slider.component';
import { TabsMenuComponent } from './components/tabs-menu/tabs-menu.component';
import { TagComponent } from './components/tag/tag.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { UploadImageV2Component } from './components/upload-image-v2/upload-image-v2.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { UsefullnumbersCardComponent } from './components/usefullnumbers-card/desktop/usefullnumbers-card.component';
import { UsefullnumbersCardMobileComponent } from './components/usefullnumbers-card/mobile/usefullnumbers-card-mobile.component';
import { YaagoV2PropertyCardTitleComponent } from './components/yaago-v2-property-card-title/yaago-v2-property-card-title.component';
import { YaagoV2QrcodeComponent } from './components/yaago-v2-qrcode/yaago-v2-qrcode.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { CustomMomentPipe } from "./pipes/customMoment.pipe";
import { SafePipe } from './pipes/safe.pipe';
import {BookingRecapComponent} from "./components/booking-recap/booking-recap.component";
import { MultiplePropertySelectorComponent } from './components/multiple-property-selector/multiple-property-selector.component';
import { PropertyGuideSelectorComponent } from './components/property-guide-selector/property-guide-selector.component';
import { GuestCommunicationComponent } from './components/guest-communication/guest-communication.component';
import {PermissionClaimDirective} from "./directives/permission-claim.directive";
import {CircularLoaderComponent} from "./components/circular-loader/circular-loader.component";
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import {LeafletMapComponent} from "./components/leaflet-map/leaflet-map.component";
import { LanguageChooserComponent } from './components/language-chooser/language-chooser.component';
import { WidgetPricingPlanComponent } from './components/widget-pricing-plan/widget-pricing-plan.component';
import {MAT_CHIPS_DEFAULT_OPTIONS, MatChipsModule} from "@angular/material/chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import { SmartlockCardComponent } from './components/smartlock-card/smartlock-card.component';
import { C2BookingEditionComponent } from './components/c2-booking-edition/c2-booking-edition.component';
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {MatMenuModule} from "@angular/material/menu";
import { C3CheckOnlineRecapComponent } from './components/c3-check-online-recap/c3-check-online-recap.component';
import { C4TaskOnRecapComponent } from './components/c4-task-on-recap/c4-task-on-recap.component';

import { TriStateToggleComponent } from './components/tri-state-toggle/tri-state-toggle.component';
import { CategorizedSettingsComponent } from './components/categorized-settings/categorized-settings.component';
import {NgxColorsModule} from "ngx-colors";
import {GroupsPropertiesListComponent} from "../setup/y3-settings/groups-properties-list/groups-properties-list.component";

import { MultiplePropertySelectorV2Component } from './components/multiple-property-selector-v2/multiple-property-selector-v2.component';
import {MatDrawer, MatDrawerContainer, MatDrawerContent, MatSidenavModule} from "@angular/material/sidenav";
import { C5TaskRecapComponent } from './components/c5-task-recap/c5-task-recap.component';
import { C6TaskCardComponent } from './components/c6-task-card/c6-task-card.component';
import { C7TaskPersonsComponent } from './components/c7-task-persons/c7-task-persons.component';
import { C8BookletViewerComponent } from './components/c8-booklet-viewer/c8-booklet-viewer.component';
import { Tag2Component } from './components/tag2/tag2.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = { wheelPropagation: true };

@NgModule({
  declarations: [
    LeafletMapComponent,
    UploadImageComponent,
    UploadImageV2Component,
    NavTopMenuComponent,
    SharedLanguageSliderComponent,
    SharedToggleButtonComponent,
    PropertyCalendarListComponent,
    InviteCardComponent,
    QrCodeComponent,
    CarouselComponent,
    CarouselItemDirective,
    CarouselItemElement,
    UsefullnumbersCardMobileComponent,
    UsefullnumbersCardComponent,
    SharedFulladdressInputComponent,
    SliderComponent,
    CustomDatepickerHeaderComponent,
    CustomDatepickerHeaderBetaComponent,
    PropertyHeaderComponent,
    PropertyHeaderComponent,
    SharedPanHelpSimpleComponent,
    ButtonComponent,
    TooltipComponent,
    DebounceClickDirective,
    ServiceInfoComponent,
    TabsMenuComponent,
    PersonInfoComponent,
    TagComponent,
    BookingRecapComponent,
    ConfirmationDialogComponent,

    // Pipes
    CustomDatePipe,
    SharedSnackbarFormodalComponent,
    CircularStepperComponent,
    ClickOutsideDirective,
    LocationPickerComponent,
    CustomMomentPipe,
    SharedUploadComponent,
    SafePipe,
    ExternalVideoBoxComponent,
    YaagoV2PropertyCardTitleComponent,
    YaagoV2QrcodeComponent,
    MultiplePropertySelectorComponent,
    PropertyGuideSelectorComponent,
    GuestCommunicationComponent,
    GuestCommunicationComponent,
    CircularLoaderComponent,
    PermissionClaimDirective,
    LanguageChooserComponent,
    WidgetPricingPlanComponent,
    SmartlockCardComponent,
    C2BookingEditionComponent,
    C3CheckOnlineRecapComponent,
    C4TaskOnRecapComponent,
    TriStateToggleComponent,
    CategorizedSettingsComponent,
    GroupsPropertiesListComponent,
    MultiplePropertySelectorV2Component,
    C5TaskRecapComponent,
    C6TaskCardComponent,
    C7TaskPersonsComponent,
    C8BookletViewerComponent,
    Tag2Component
  ],
    imports: [
        TranslateModule,
        CommonModule,
        QuillModule,
        MatCheckboxModule,MatProgressSpinnerModule,
        MatExpansionModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        MatIconModule,
        RouterModule,
        MatRippleModule,
        MatDatepickerModule,
        MatSelectModule,
        NgxMatDatetimePickerModule,
        DragDropModule,
        MatChipsModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatMenuModule,
        NgxColorsModule,
        MatSlideToggleModule,
        MatSidenavModule,
    ],
  exports: [
    // Angular Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxColorsModule,

    // Material Design Shared Module
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatExpansionModule,
    MatDialogModule,
    MatDatepickerModule,
    MatIconModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatSelectModule,
    MatButtonModule,
    MatRippleModule,
    NgxMatDatetimePickerModule,
    DragDropModule,
    MatChipsModule,
    NgMultiSelectDropDownModule,
    MatMenuModule,

    // Other shared modules,
    NgxMaterialTimepickerModule,
    NgxIntlTelInputModule,
    PerfectScrollbarModule,
    TranslateModule,
    QuillModule,
    NgMultiSelectDropDownModule,

    // Directive,
    DebounceClickDirective,
    ClickOutsideDirective,
    PermissionClaimDirective,

    // Shared Components
    UploadImageComponent,
    UploadImageV2Component,
    NavTopMenuComponent,
    PropertyCalendarListComponent,
    CarouselItemDirective,
    SharedLanguageSliderComponent,
    SharedToggleButtonComponent,
    InviteCardComponent,
    QrCodeComponent,
    CarouselComponent,
    UsefullnumbersCardMobileComponent,
    UsefullnumbersCardComponent,
    SharedFulladdressInputComponent,
    SliderComponent,
    CustomDatepickerHeaderComponent,
    CustomDatepickerHeaderBetaComponent,
    PropertyHeaderComponent,
    SharedPanHelpSimpleComponent,
    ButtonComponent,
    TooltipComponent,
    TabsMenuComponent,
    ServiceInfoComponent,
    TagComponent,
    CircularStepperComponent,
    LocationPickerComponent,
    BookingRecapComponent,
    MultiplePropertySelectorComponent,
    PropertyGuideSelectorComponent,
    CircularLoaderComponent,
    ConfirmationDialogComponent,
    LeafletMapComponent,
    WidgetPricingPlanComponent,
    TriStateToggleComponent,
    CategorizedSettingsComponent,


    // Pipes
    CustomDatePipe,
    PersonInfoComponent,
    SharedSnackbarFormodalComponent,
    CustomMomentPipe,
    SharedUploadComponent,
    SafePipe,
    ExternalVideoBoxComponent,
    YaagoV2PropertyCardTitleComponent,
    YaagoV2QrcodeComponent,
    LanguageChooserComponent,
    SmartlockCardComponent,
    C2BookingEditionComponent,
    GroupsPropertiesListComponent,
    TriStateToggleComponent,
    CategorizedSettingsComponent,
    MultiplePropertySelectorV2Component,
    // MatDrawer,
    // MatDrawerContent,
    // MatDrawerContainer,
    C5TaskRecapComponent,
    C6TaskCardComponent,
    C3CheckOnlineRecapComponent,
    C4TaskOnRecapComponent,
    C8BookletViewerComponent,
    Tag2Component
  ],
  entryComponents: [
    CustomDatepickerHeaderComponent,
    CustomDatepickerHeaderBetaComponent,
    UploadImageV2Component,
    UploadImageComponent,
    CarouselComponent,
    GuestCommunicationComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    {provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: {separatorKeyCodes: [ENTER, COMMA]}},
    TitleCasePipe,
    CustomMomentPipe
  ]
})
export class SharedModule { }
