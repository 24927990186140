import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './modules/shared/shared.module';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgImageSliderModule } from 'ng-image-slider';
import { TarifComponent } from './modules/tarif/tarif-page/tarif.component';
import { AudioRecordingService } from './modules/property/internal-chat/record-audio/audio-recording.service';
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    TarifComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,  // Animation for the Snackbar Mainly
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    CoreModule,
    SharedModule,
    NgImageSliderModule,
  ],
  providers: [AudioRecordingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
