import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfService} from '../../services/ConfService';
import {flatMap} from 'rxjs/operators';
import {TokenService} from '../TokenService';
import {Observable} from 'rxjs';
import {Property} from '../../../model/Property';
import {UsefulNumber} from '../../../model/UsefulNumber';
import {HttpService} from '../HttpService';
import {NumberToProperties} from "../../../model/modeldto/NumberToProperties";

@Injectable({ providedIn: 'root' })
export class UsefulNumberEndpoint {
  constructor(private http: HttpClient,
              private httpService: HttpService,
              private tokenService: TokenService,
              private confService: ConfService) {
  }
  createUsefulNumber(propertyUsefulNumber: UsefulNumber, propertyId: string): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/usefulnumber/' + propertyId, propertyUsefulNumber, this.httpService.getHeaders(to));
    }));
  }
  updateUsefulNumber(propertyUsefulNumber: UsefulNumber, propertyId: string): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/usefulnumber/' + propertyId, propertyUsefulNumber, this.httpService.getHeaders(to));
    }));
  }

  updateUsefulNumbers(propertyUsefulNumbers: UsefulNumber[], propertyId: string): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/usefulnumber/sorted/' + propertyId, propertyUsefulNumbers, this.httpService.getHeaders(to));
    }));
  }

  usefulNumberCopyFrom(fromPropertyId: string, toPropertyId: string): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/usefulnumber/copyfrom/' + fromPropertyId + '/' + toPropertyId, null, this.httpService.getHeaders(to));
    }));
  }

  deleteUsefulNumber(propertyUsefulNumberId: string, propertyId: string): Observable<Property> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('numberId', propertyUsefulNumberId);
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/usefulnumber/' + propertyId, this.httpService.getOptionsWithParams(to, httpParams));
    }));
  }

  copyToProperties(numberToProperties: NumberToProperties): Observable<void> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<void>(this.confService.getUrlApiEndPoint() + 'api-v2/property/usefulnumber/exportToProperties', numberToProperties, this.httpService.getHeaders(to));
    }));
  }
}
