import {PropertyOwner} from './secondary/PropertyOwner';
import {PlatformCalendar} from './secondary/PlatformCalendar';
import {Person} from './Person';
import {UsefulNumber} from './UsefulNumber';
import {Guide} from './Guide';
import {Membership} from './secondary/Membership';
import {ActionSetup} from './secondary/ActionSetup';
import {CohostInvitation} from './CohostInvitation';
import {TeammateInvitation} from './TeammateInvitation';
import {Photo} from './secondary/Photo';
import {Poi} from './secondary/Poi';
import {Amount} from './secondary/Amount';
import {NukiAttach} from './nuki/NukiAttach';
import {TaskTeammateInCharge} from "./PropertyBooking";
import {TaskCheckItem} from "./TaskCheckItem";
import {TaskCheckDetail} from "./TaskCheckDetail";


export class PropertyLight {
  title: string;
  fullAddress: string;
  pictureUrl: string;
}

export class PropertyIdPaused {
  propertyId: string;
  stripeSubscriptionPaused: boolean;
}

export class Property {
  id: string;
  corporateId: string;

  title: string;
  internalTitle: string;
  // Deprecated
  tags: string[];

  description: string;
  fullAddress: string;

  poi: Poi;

  bedrooms: number = 0;
  bathrooms: number = 0;
  showerRooms: number = 0;

  kingSizeBeds: number;
  queenSizeBeds: number;

  doubleBeds: number = 0;
  sofaBeds: number = 0;
  singleBeds: number = 0;
  babyBeds: number = 0;
  numberOfFloors: number = 0 ;
  personCapacity: number = 0;
  parkings: number = 0;

  numberOfLimitedNights: number;

  owners: PropertyOwner[];
  calendars: PlatformCalendar[];

  creationDate: string;
  timeZone: string;
  updateDate: string;
  teammateIds: string[];
  primaryTeammateId: string;
  teammates: Person[];
  hosts: Person[];
  tinyUrl: string;
  photos: Photo[];
  airbnbId: number;
  guides: Guide[];
  usefulNumbers: UsefulNumber[];
  membership: Membership;
  teammateInvitations: TeammateInvitation[];
  keyringSetup: boolean;
  forceMajeureItems: string[];
  cohostInvitations: CohostInvitation[];
  actions: ActionSetup[];
  iglooHomePartnerInfo: IglooHomePartnerInfo;
  theKeysInfo: TheKeysPartnerInfo;
  swiklyDepositInfo: SwiklyDepositInfo;
  stripeSubscriptionPaused: boolean;
  stripeSubscriptionPausedDateFrom: string;
  matterportUrl: string;
  minutPartnerInfo: MinutPartnerInfo;
  nukiAttacheds: NukiAttach[];
  internalInformation: InternalInformation;
  taskTeammateInChargeList: TaskTeammateInCharge[];
  guideEditableLanguages : any
  tasksCheckList: TaskCheckItem[];
  taskCheckDetails: TaskCheckDetail[];
  configProfileName: string;
  otaListings: OtaListing[];
  bookletTitle: string;

  cleaningAmount: Amount;
  cleaningAmountOwner: Amount;
  checkInAmount: Amount;
  checkInAmountOwner: Amount;
  checkOutAmount: Amount;
  checkOutAmountOwner: Amount;
  preCheckAmount: Amount;
  preCheckAmountOwner: Amount;

  preCheckInternalNote: string;
  checkInInternalNote: string;
  checkOutInternalNote: string;
  cleaningInternalNote: string;

  maxPets: number;

  keynestInfo: KeynestInfo;
}

export class KeynestInfo {
  KeyName: string;
  KeyId: string;
  attachedDateTime: string;
  attachedEmailPerson: string;

}

export class OtaListing {
  listingId: string;
  roomId: string;
  platform: string;
  platformAccountEmail: string;
  disabled: boolean;
}

export class InternalInformation {
  ownerName: string;
  ownerFirstname: string;
  ownerPhone: string;
  ownerEmail: string;
  ownerFulladdress: string;

  regroupName: string;
  internalNotes: string;
  internalInfo: string;
  photoFacade: string;
  consignes: string[];
}
export class OtaAccount {
  accountId: string;
  platform: string;
}
export class IglooHomePartnerInfo {
  iglooHomeAutomation: boolean;
  iglooHomeAutomationForTasks: boolean;
  iglooHomeApiKey: string;
  iglooHomeLockId: string;
  iglooHomeLockName: string;
  id_token: string;
  igloohomeConnect: boolean;

  attachedDateTime: string;
  attachedEmailPerson: string;

  iglooHomeStartAvailabilityTime: string;
  iglooHomeEndAvailabilityTime: string;
  iglooHomeDisplayWelcomeBooklet: boolean;
  iglooHomeEnabledEmail: boolean;
  iglooHomeEnabledSMS: boolean;
  iglooHomeEnabledWhatsApp: boolean;
  iglooHomeOneDayBefore: boolean;
  iglooHomeTwoDaysBefore: boolean;
  iglooHomeOneHourBefore: boolean;
}
export class TheKeysPartnerInfo {
  theKeysAutomation: boolean;
  theKeysUsername: string;
  theKeysLockId: number;
  theKeysLockName: string;
  theKeysStartAvailabilityTime: string;
  theKeysEndAvailabilityTime: string;
  theKeysDisplayWelcomeBooklet: boolean;
  theKeysEnabledEmail: boolean;
  theKeysEnabledSMS: boolean;
  theKeysEnabledWhatsApp: boolean;
  theKeysOneDayBefore: boolean;
  theKeysTwoDaysBefore: boolean;
  theKeysOneHourBefore: boolean;
}
export class SwiklyDepositInfo {
  amount: Amount;
  userId: string;
  airbnbAutomatic: boolean;
  bookingDotcomAutomatic: boolean;
  vrboAutomatic: boolean;
  otherAutomatic: boolean;
  handlePendingAmount: boolean;
  selectedCurrencyItem = [];
}

export enum Events {
  BOOKING_CREATION = 'Booking-creation',
  BOOKING_CANCELLATION = 'Booking-cancellation',
  CHECKIN = 'Check-in',
  CHECKOUT = 'Check-out',
}

export enum RecipientType {
  GUEST = 'Guest',
  HOST = 'Host',
  TEAMMATE = 'Teammate'
}
export class MinutPartnerInfo {
  minutHomeId: string;
  minutHomeName: string;
}
export class CoHostInCharge {
  coHostId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneUrgencyOnly: boolean;
  whatsAppLink: string;
  pictureUrl: string;
  languages: string[];

  linkedInUrl: string;
  instagramUrl: string;
  facebookUrl: string;
  webSiteUrl: string;
  openHours: string;
  fullAddress: string;
  coHostLabel: string = 'your-host';
}
