import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {LoaderType} from '../../../model/secondary/LoaderType';
import {Yaago2LoaderService} from '../../services/Yaago2-LoaderService';
import {TranslateService} from '@ngx-translate/core';
import {AbstractWindow} from '../../abstract/AbstractWindow';
import {IntercomService} from '../../endpoints/IntercomService';
import {UtilsService} from '../../services/UtilsService';
import {AnimationOptions} from 'ngx-lottie';

@Component({
  selector: 'app-yaago2-loader',
  templateUrl: './yaago2-loader.component.html',
  styleUrls: ['./yaago2-loader.component.scss']
})
export class Yaago2LoaderComponent extends AbstractWindow implements OnInit, OnDestroy {

  options: AnimationOptions = {
    path: '/assets/images/yaago2-loader/animation.json',
  };

  showFull = false;
  mainText: string;
  private clickHandler: any = this.preventClick.bind(this);

  constructor(public intercomService: IntercomService,
              public utilsService: UtilsService,
              private yaago2LoaderService: Yaago2LoaderService,
              public translateService: TranslateService) {
    super(intercomService, translateService, utilsService);
  }

  ngOnInit() {
    this.yaago2LoaderService.showLoaderEvent$.pipe(takeUntil(this.onDestroy)).subscribe((value: LoaderType) => {

      this.showFull = true; // value.type === LoaderService.FULL;
      window.scrollTo(0, 0);
      if (value.message != null && value.message !== '') {
        this.mainText = this.translateService.instant(value.message);
      } else {
        this.mainText = '';
      }
    });

    this.yaago2LoaderService.dismissLoaderEvent$.pipe(takeUntil(this.onDestroy)).subscribe(_ => {
      this.showFull = false;
      this.mainText = undefined;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.enableClick();
  }

  preventClick(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
  }

  enableClick() {
    document.removeEventListener('click', this.clickHandler, true);
  }

  closeLoader() {
    this.yaago2LoaderService.closeLoader();
  }

}
