import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { FirebaseUser } from '../../model/firebase/FirebaseUser';

@Injectable()
export class BugsnagChunkLoadErrorHandler extends BugsnagErrorHandler {

  private static readonly chunkFailedMessage: RegExp = /Loading chunk [\d]+ failed/;

  constructor() {
    if (window['bugsnagApiKey'] !== '00000000000000000000000000000000') {
      Bugsnag.start({apiKey: window['bugsnagApiKey'],
        onError: event => {
          if (localStorage.getItem('yaago_firebase_user') != null) {
            const user: FirebaseUser = JSON.parse(localStorage.getItem('yaago_firebase_user'));
            event.setUser(user.yaagoUser.id, user.yaagoUser.email, (user.yaagoUser.firstName || '') + (user.yaagoUser.lastName || ''));
          }
        },
        // appVersion: 'commit: ' + window['commitId'] + ' - pipelineId: ' +  window['pipelineId'] + ' - ' + window['buildDatetime']
      });
    }
    super();
  }

/*
  setUser(user: FirebaseUser): void {
    if (this.bugsnagClient != null && window['bugsnagApiKey'] !== '00000000000000000000000000000000') {
      if (user != null && user.yaagoUser != null) {
        this.bugsnagClient.setUser(user.yaagoUser.id, user.yaagoUser.email, (user.yaagoUser.firstName || '') + (user.yaagoUser.lastName || ''));
      }
    }
  }
*/


  handleError(error: any): void {
/*    if (localStorage.getItem('yaago_firebase_user') != null) {
      const user: FirebaseUser = JSON.parse(localStorage.getItem('yaago_firebase_user'));
      this.setUser(user);
    }*/

    if (window['bugsnagApiKey'] !== '00000000000000000000000000000000') {
      if (error && error.status !== 200) {
        if (BugsnagChunkLoadErrorHandler.chunkFailedMessage.test(error.message)) {
          console.log('Chunk load error - reload.');
          window.location.reload();
        } else {
          super.handleError(error);
        }
      } else {
        console.log(error);
      }
    } else {
      console.log(error);
    }
  }

}
