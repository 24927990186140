
import {Injectable} from '@angular/core';
import {Intercom} from 'ng-intercom';
import {IntercomSettings} from '../../model/intercom/IntercomSettings';
import {IntercomUser} from '../../model/intercom/IntercomUser';
import {IntercomAvatar} from '../../model/intercom/IntercomAvatar';
import {FirebaseUser} from '../../model/firebase/FirebaseUser';
import {CrispService} from "./CrispService";

@Injectable({ providedIn: 'root' })
export class IntercomService {

  private settings: IntercomSettings;
  private user: IntercomUser;
  public isIntercomOpen = false;

  constructor(private intercom: Intercom,
              private crispService: CrispService) { }

  init() {
    //this.settings = new IntercomSettings(window["intercom_app_id"]);
    //this.settings.hide_default_launcher = true; // hide on mobile
    //this.intercom.boot(this.settings);
    this.crispService.init();
  }

  setUser(fbUser: FirebaseUser) {
    this.user = new IntercomUser();
    this.user.user_id = fbUser.uid;
    this.user.email = fbUser.email;
    if (fbUser.yaagoUser && fbUser.yaagoUser.phone) {
      this.user.phone = fbUser.yaagoUser.phone;
    }
    if (fbUser.yaagoUser && fbUser.yaagoUser.firstName) {
      this.user.name = fbUser.yaagoUser.firstName[0].toUpperCase() + fbUser.yaagoUser.firstName.slice(1).toLowerCase();
    }
    if (fbUser.yaagoUser && fbUser.yaagoUser.lastName) {
      this.user.name = this.user.name + " " + fbUser.yaagoUser.lastName;
    }
    if (fbUser.yaagoUser && fbUser.yaagoUser.creationTime) {
      this.user.created_at = Math.round(new Date(fbUser.yaagoUser.creationTime).getTime() / 1000);
    }
    this.user.avatar = new IntercomAvatar();
    this.user.avatar.image_url = fbUser.photoURL;
    this.update();
  }

  update() {
    if (this.user && this.user.email) {
      this.user.last_request_at = Math.round(new Date().getTime()  / 1000);
      this.intercom.update(this.user);
    } else {
      // no logged in user
      const time = Math.round(new Date().getTime() / 1000);
      this.intercom.update({last_request_at: time});
    }
  }

  openMessenger() {
    this.isIntercomOpen = true;
    this.intercom.show();
  }

  closeMessenger() {
    this.isIntercomOpen = false;
    this.intercom.hide();
  }

  updateAvatar(url) {
    if(this.user){
      this.user.avatar.image_url = url;
      this.update();
    }

  }

  display(show: boolean) {
    if (show) {
      this.intercom.update({
        hide_default_launcher: false
      });
    } else {
      this.intercom.update({
        hide_default_launcher: true
      });
    }
  }

  screenResize(isDesktopMode) {
    if (!isDesktopMode) {
      this.intercom.update({
        hide_default_launcher: true
      });
    } else {
      this.intercom.update({
        hide_default_launcher: false
      });
    }
  }

  logout() {
    console.log('*********** logout reboot intercom **********');
    this.intercom.shutdown();
    console.log('Shutdown');
    window.setTimeout(() => {
      this.intercom.boot(this.settings);
      console.log('Boot');
    }, 1000);
  }
}
