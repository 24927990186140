import {Injectable} from '@angular/core';
import {OnBoarding} from '../../../model/secondary/OnBoarding';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpService} from '../HttpService';
import {TokenService} from '../TokenService';
import {ConfService} from '../../services/ConfService';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, flatMap} from 'rxjs/operators';
import {ReturnUrl} from '../../../model/secondary/ReturnUrl';
import {Person} from '../../../model/Person';
import {Property} from '../../../model/Property';
import {LoginDto} from '../../../model/secondary/LoginDto';
import {HostData} from '../../../model/secondary/HostData';
import {FirebaseUser} from '../../../model/firebase/FirebaseUser';
import {PersonOtaAccountsDto} from '../../../model/secondary/PersonOtaAccountsDto';
import {ConsumptionDto} from "../../../model/secondary/ConsumptionDto";
import {RegistrationFormula} from "../../../model/secondary/RegistrationFormula";

@Injectable({ providedIn: 'root' })
export class PersonEndpoint {
  constructor(private http: HttpClient,
              private httpService: HttpService,
              private tokenService: TokenService,
              private confService: ConfService) {
  }
  private globalCurrentUser = new BehaviorSubject<Person>(undefined);
  private globalCurrentUser$ = this.globalCurrentUser.asObservable();

  getCurrentUser(): Observable<Person> { return this.globalCurrentUser$; }
  setCurrentUser(refUser: Person) { this.globalCurrentUser.next(refUser);  }

  public login(loginDto: LoginDto): Observable<LoginDto> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<LoginDto>( this.confService.getUrlApiEndPoint() + 'api-v2/auth', loginDto, this.httpService.getHeaders(to));
    }));
  }
  setOnboarded(data: OnBoarding): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person/onboarded', data, this.httpService.getHeaders(to));
    }));
  }
  setOnboardedNotOwner(): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person/onboarded-and-notowner', null, this.httpService.getHeaders(to));
    }));
  }
  setOnboardedInfluencer(): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person/onboarded-and-influencer', null, this.httpService.getHeaders(to));
    }));
  }

  getMyCorporateOwner(): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person/getmycorporateowner', this.httpService.getHeaders(to))
    }));
  }

  getStripeClientPortal(refUrl: string): Observable<ReturnUrl> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      let httpParams = new HttpParams();
      httpParams = httpParams.set('refUrl', encodeURI(refUrl));
      return this.http.get<ReturnUrl>(this.confService.getUrlApiEndPoint() + 'api-v2/person/stripe/clientportal', this.httpService.getOptionsWithParams(to, httpParams));
    }));
  }

  forceStripeStatus(): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person/stripe/force-status', this.httpService.getHeaders(to));
    }));
  }

  createGuruUser(person: Person): Observable<Person> {
      return this.http.post<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person', person, this.httpService.getSimpleHeaders());
  }
  updateGuruUser(person: Person): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person', person, this.httpService.getHeaders(to));
    }));
  }
  getGuruUser(): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person/me', this.httpService.getHeaders(to))
        .pipe(
          catchError((err) => {
            console.log('error caught in service');
            console.log(err.status);
            return throwError(err);
          })
        );
    }));
  }
  resendEmailConfirmation(): Observable<void> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<void>(this.confService.getUrlApiEndPoint() + 'api-v2/firebase/resend', null,  this.httpService.getHeaders(to));
    }));
  }

  resendEmailConfirmationWithEmail(email: string): Observable<void> {
      let httpParams = new HttpParams();
      httpParams = httpParams.set('email', encodeURI(email));
      return this.http.get<void>(this.confService.getUrlApiEndPoint() + 'api-v2/firebase/resend-with-email', this.httpService.getSimpleOptionsWithParams(httpParams));
  }

  sendResetPasswordEmail(email: string, lang: string): Observable<void> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('email', encodeURI(email));
    httpParams = httpParams.set('language', lang);
    return this.http.get<void>(this.confService.getUrlApiEndPoint() + 'api-v2/firebase/reset', this.httpService.getSimpleOptionsWithParams(httpParams));
  }

  getOtaAccount(): Observable<PersonOtaAccountsDto> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<PersonOtaAccountsDto>(this.confService.getUrlApiEndPoint() + 'api-v2/person/ota/accounts', this.httpService.getHeaders(to));
    }));
  }

  getConsumptionPerPersonId(personId: string): Observable<ConsumptionDto> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<ConsumptionDto>(this.confService.getUrlApiEndPoint() + 'api-v2/person/sms/consumption/' + personId, this.httpService.getHeaders(to));
    }));
  }

  getConsumption(): Observable<ConsumptionDto> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<ConsumptionDto>(this.confService.getUrlApiEndPoint() + 'api-v2/person/sms/consumption', this.httpService.getHeaders(to));
    }));
  }


  changeMyPassword(password): Observable<void> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<void>(this.confService.getUrlApiEndPoint() + 'api-v2/person/my-password', password, this.httpService.getHeaders(to));
    }));
  }

  changeMyEmail(objetToSend): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person/change-email', objetToSend, this.httpService.getHeaders(to));
    }));
  }

  registrationFormula(registrationFormula: RegistrationFormula): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person/registration-formula', registrationFormula, this.httpService.getHeaders(to));
    }));
  }

}
