import {HttpClient} from '@angular/common/http';
import {HttpService} from '../HttpService';
import {TokenService} from '../TokenService';
import {ConfService} from '../../services/ConfService';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Property} from '../../../model/Property';
import {flatMap} from 'rxjs/operators';
import {KeyRingBulkUpdate} from "../../../model/KeyRingBulkUpdate";
import {KeyringSearchRequest} from "../../../model/keyring/KeyringSearchRequest";
import {
  PropertyForMultipleListDto,
  PropertyForMultipleListDtoListResponse
} from "../../../model/PropertyForMultipleListDto";

@Injectable({ providedIn: 'root' })
export class KeyringEndPoint {
  constructor(private http: HttpClient,
              private httpService: HttpService,
              private tokenService: TokenService,
              private confService: ConfService) {
  }
  registerKeyring(propertyId, keyCode): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/keyring/' + propertyId + '/' + keyCode, null, this.httpService.getHeaders(to));
    }));
  }
  unRegisterKeyring(propertyId): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/keyring/' + propertyId, this.httpService.getHeaders(to));
    }));
  }
  getAllKeyrings(): Observable<Property[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Property[]>(this.confService.getUrlApiEndPoint() + 'api-v2/property/keyring/all', this.httpService.getHeaders(to));
    }));
  }

  searchAllKeyring(keyringSearchRequest: KeyringSearchRequest): Observable<PropertyForMultipleListDtoListResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<PropertyForMultipleListDtoListResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/property/keyring/search', keyringSearchRequest, this.httpService.getHeaders(to));
    }));
  }
  bulkUpdate(keyRingBulkUpdates: KeyRingBulkUpdate[]): Observable<string[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<string[]>(this.confService.getUrlApiEndPoint() + 'api-v2/property/keyring/bulkUpdate', keyRingBulkUpdates, this.httpService.getHeaders(to));
    }));
  }
}
