import {Injectable} from '@angular/core';
import {SlackMessage} from '../../model/slack/SlackMessage';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PlatformCalendar} from '../../model/secondary/PlatformCalendar';

@Injectable({ providedIn: 'root' })
export class SlackService {
  constructor(private http: HttpClient) {
  }
  getHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Headers', 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method')
        .set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, DELETE')
    };
  }
  sendMessage(slackMessage: SlackMessage) {
    try {
      console.log(JSON.stringify(slackMessage));
      return this.http.post<any>(window['slackUrl'], JSON.stringify(slackMessage), {});
    } catch (err) {
      return null;
    }
  }

  /*sendMessageForNewUsers(slackMessage: SlackMessage) {
    try {
      console.log(JSON.stringify(slackMessage));
      return this.http.post<any>(window['slackUrlForNewUser'], JSON.stringify(slackMessage), {});
    } catch (err) {
      return null;
    }
  }*/
}
