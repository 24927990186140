import {HttpClient} from "@angular/common/http";
import {HttpService} from "../HttpService";
import {TokenService} from "../TokenService";
import {ConfService} from "../../services/ConfService";
import {PropertyService} from "../../services/YaagoServices/PropertyService";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {flatMap} from "rxjs/operators";
import {ThreadListResponse} from "../../../model/thread/ThreadListResponse";
import {ThreadSearchRequest} from "../../../model/thread/ThreadSearchRequest";

@Injectable({ providedIn: 'root' })
export class ThreadEndpoint {
  constructor(private http: HttpClient,
              private httpService: HttpService,
              private tokenService: TokenService,
              private confService: ConfService,
              private propertyService: PropertyService) {
  }
  searchThreads(searchRequest: ThreadSearchRequest): Observable<ThreadListResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<ThreadListResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/messages/thread', searchRequest, this.httpService.getHeaders(to));
    }));
  }

  searchThreadsNew(searchRequest: ThreadSearchRequest): Observable<ThreadListResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<ThreadListResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/messages/thread-list', searchRequest, this.httpService.getHeaders(to));
    }));
  }
  searchThreadCount(): Observable<Number> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Number>(this.confService.getUrlApiEndPoint() + 'api-v2/messages/thread-new-count', null, this.httpService.getHeaders(to));
    }));
  }
}
