<div class="scrollable-container-new">
  <div *ngIf="yaagoUser!=null" class="menu-container" [ngClass]="getProductionColor()">
    <app-yaago2-topnavbar *ngIf="!(urlAfterRedirects.indexOf('register/abo') > 0)
        && !(urlAfterRedirects.indexOf('register/registration') > 0)
        && !(urlAfterRedirects.indexOf('register/corporate') > 0)
        && !(urlAfterRedirects.indexOf('login') > 0)
        && !(urlAfterRedirects.indexOf('support/widget') > 0)"></app-yaago2-topnavbar>
  </div>
  <router-outlet id="scrollable-container" class="scrollable-container" (scroll)="handleScroll($event)" #outlet ></router-outlet>
  <app-snackbar></app-snackbar>
  <app-yaago2-loader></app-yaago2-loader>
  <app-loader></app-loader>
</div>





