import {HttpClient} from "@angular/common/http";
import {HttpService} from "../HttpService";
import {TokenService} from "../TokenService";
import {ConfService} from "../../services/ConfService";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {PermissionBloc} from "../../../model/PlatformRole";
import {flatMap} from "rxjs/operators";
import {ReleaseNote} from "../../../model/release/ReleaseNote";
import {IncidentNote} from "../../../model/release/IncidentNote";

@Injectable({ providedIn: 'root' })
export class ReleaseNoteEndpoint {
  constructor(private http: HttpClient,
              private httpService: HttpService,
              private tokenService: TokenService,
              private confService: ConfService) {
  }

  getReleaseNote(): Observable<ReleaseNote[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<ReleaseNote[]>(this.confService.getUrlApiEndPoint() + 'api-v2/release-note', this.httpService.getHeaders(to));
    }));
  }

  getReleaseNoteRoadMap(): Observable<ReleaseNote[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<ReleaseNote[]>(this.confService.getUrlApiEndPoint() + 'api-v2/release-note/roadmap', this.httpService.getHeaders(to));
    }));
  }

  getIncidentNotes() : Observable<IncidentNote[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<IncidentNote[]>(this.confService.getUrlApiEndPoint() + 'api-v2/incident-note', this.httpService.getHeaders(to));
    }));
  }

  getIncidentNotesWithLanguage(language: string) : Observable<IncidentNote[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<IncidentNote[]>(this.confService.getUrlApiEndPoint() + 'api-v2/incident-note/' + language, this.httpService.getHeaders(to));
    }));
  }
}
