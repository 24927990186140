import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() debounceTime = 300;
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() type: ButtonType = ButtonType.PRIMARY;
  @Input() routerLink: any[] | string;
  @Input()  queryParams: {
    [k: string]: any;
  };
  @Input() rightIcon: string;
  @Input() leftIcon: string;
  @Input() leftBrutIcon: string;
  @Input() width: string;
  @Output() debounceClick = new EventEmitter<any>();

  ButtonType: typeof ButtonType = ButtonType;

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    this.debounceClick.emit();
  }

}

export enum ButtonType {
  PRIMARY = 'primary',
  PRIMARY_X_SMALL = 'primary-x-small',
  PRIMARY_SMALL = 'primary-small',
  NEW_PRIMARY = 'new-primary',
  SECONDARY = 'secondary',
  NEW_SECONDARY = 'new-secondary',
  NEW_SECONDARY_SMALL = 'new-secondary-small',
  SECONDARY_SMALL = 'secondary-small',
  SECONDARY_X_SMALL = 'secondary-x-small',
  SECONDARY_X_SMALL_WHITE = 'secondary-x-small-white',
  SECONDARY_LIGHT = 'secondary-light',
  TERNARY = 'ternary',
  TERNARY_DUNESSANT = 'ternary-dunessant',
  TERNARY_RED = 'ternary-red',
  TERNARY_LIGHT = 'ternary-light',
  SHADOW = 'shadow',
  NAVIGATION = 'navigation',
  STEPH_BUTTON_BLUE = 'steph-button-blue',
  STEPH_BUTTON_VIOLET = 'steph-button-violet',
  events_new_primary = 'events-new-primary',
  events_new_primary_p4 = 'events-new-primary-p4',
  events_new_primary_full = 'events-new-primary-full',
  events_new_secondary_full = 'events-new-secondary-full'
}
