import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirebaseUser } from '../../../model/firebase/FirebaseUser';
import { Person } from '../../../model/Person';
import { HostData } from '../../../model/secondary/HostData';
import { FirebaseService } from '../../endpoints/FirebaseService';
import { PersonEndpoint } from '../../endpoints/YaagoEndpoints/PersonEndpoint';
import { LoaderService } from '../LoaderService';

const CURRENT_USER = 'yaago_user';
const YAAGO_CAMPAIGN = 'yaago_campaign';

@Injectable({ providedIn: 'root' })
export class PersonService {
  private globalCurrentPerson = new BehaviorSubject<Person>(undefined);
  private globalCurrentPerson$ = this.globalCurrentPerson.asObservable();

  constructor(private personEndpoint: PersonEndpoint,
              private loaderService: LoaderService,
              private router: Router,
              private firebaseService: FirebaseService) {
  }

  getCurrentUser(): Person {
    return JSON.parse(localStorage.getItem(CURRENT_USER));
  }
  getCurrentCampaign(): string {
    return JSON.parse(localStorage.getItem(YAAGO_CAMPAIGN));
  }
  getCurrentUserObservable(): Observable<Person> { return this.globalCurrentPerson$; }
  setCurrentUser(refPerson: Person) {
    this.globalCurrentPerson.next(refPerson);
    localStorage.setItem(CURRENT_USER, JSON.stringify(refPerson));
  }

  saveMiniMeFirst(firebaseCredential: firebase.auth.UserCredential, phone: string, category: string, firstname: string, lastname: string) {
    const me: Person  = new Person();
    me.email = firebaseCredential.user.email;
    me.phone = phone;
    me.language = navigator.language.substring(0, 2);
    me.emailVerified = false;
    me.personCategory = category;
    me.firstName = firstname;
    me.lastName = lastname;
    // me.premiumPlan = isPremiumPlan;
    me.id = firebaseCredential.user.uid;
    console.log('me', me);
    this.personEndpoint.createGuruUser(me).subscribe( p => {
      console.log(p);
      this.setCurrentUser(p);
      if (!firebaseCredential.user.emailVerified && firebaseCredential.user.providerData.some((fu) => fu.providerId === 'password')) {
        this.personEndpoint.resendEmailConfirmation().subscribe( t => {
        });
      } else {
        this.router.navigate(['/register/add-platform']).then(() => this.loaderService.dismissLoader());
      }
    });

  }

  saveMiniMeFirstFirebaseUser(firebaseUser: FirebaseUser, phone: string, category: string, firstname: string, lastname: string) {
    const me: Person  = new Person();
    me.email = firebaseUser.email;
    me.phone = phone;
    me.language = navigator.language.substring(0, 2);
    me.emailVerified = false;
    me.personCategory = category;
    me.firstName = firstname;
    me.lastName = lastname;
    me.id = firebaseUser.uid;
    console.log('me', me);
    this.personEndpoint.createGuruUser(me).subscribe( p => {
      console.log(p);
      this.setCurrentUser(p);
      if (!firebaseUser.emailVerified && firebaseUser.providerData.some((fu) => fu.providerId === 'password')) {
        this.personEndpoint.resendEmailConfirmation().subscribe( t => {
        });
      } else {
        this.router.navigate(['/register/add-platform']).then(() => this.loaderService.dismissLoader());
      }
    });

  }

  saveMiniMe(firebaseUser: FirebaseUser) {
    const me: Person  = new Person();
    console.log('firebaseUser', firebaseUser);
    if (firebaseUser.displayName) {
      me.firstName = firebaseUser.displayName.split(' ')[0];
      me.lastName = firebaseUser.displayName.split(' ')[1];
    }
    me.host = new HostData();
    me.email = firebaseUser.email;
    me.pictureUrl = firebaseUser.photoURL;
    me.phone = firebaseUser.phoneNumber;
    me.language = navigator.language.substring(0, 2);
    me.emailVerified = firebaseUser.emailVerified;
    // me.marketingCampaign = this.getCurrentCampaign();

    this.personEndpoint.updateGuruUser(me).subscribe( p => {
      this.setCurrentUser(p);
      firebaseUser.yaagoUser = p;
      this.firebaseService.setCurrentUser(firebaseUser);
      if (!firebaseUser.emailVerified && firebaseUser.providerData.some((fu) => fu.providerId === 'password')) {
        this.personEndpoint.resendEmailConfirmation().subscribe( t => {
        });
      } else {
        this.router.navigate(['/register/add-platform']).then(() => this.loaderService.dismissLoader());
      }
    });
  }

}
