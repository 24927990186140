import {HostListener, OnInit, Directive, OnDestroy} from '@angular/core';
import {IntercomService} from '../endpoints/IntercomService';
import {UtilsService} from '../services/UtilsService';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Directive()
export abstract class AbstractWindow implements OnInit, OnDestroy {
  screenWidth: number;
  onDestroy = new Subject();
  languages: any[] = [
    {value: 'fr', viewValue: 'FR'},
    {value: 'es', viewValue: 'ES'},
    {value: 'en', viewValue: 'EN'}
  ];
  selectedValue: string;

  protected constructor(public intercomService: IntercomService,
                        public translateService: TranslateService,
                        public utilsService: UtilsService) {
    this.screenWidth = window.innerWidth;
  }
  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  changeLanguage() {
    if (this.utilsService.yaagoTranslationLanguages.indexOf(this.selectedValue) > -1 ) {
      this.translateService.use(this.selectedValue);
    } else {
      this.translateService.use('en');
    }
  }

  changeMyLanguage(myLanguage) {
    console.log(myLanguage);
    if (this.utilsService.yaagoTranslationLanguages.indexOf(myLanguage) > -1) {
      this.translateService.use(myLanguage);
    } else {
      this.translateService.use('en');
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenWidth(event?) {
    this.screenWidth = window.innerWidth;
    this.intercomService.screenResize(this.utilsService.isDesktop(this.screenWidth));
  }
  isDesktopMode() {
    return this.utilsService.isDesktop(this.screenWidth);
  }
  isTabletMode() {
    return this.utilsService.isTablet(this.screenWidth);
  }
  isMobileMode() {
    return this.utilsService.isMobile(this.screenWidth);
  }
  manageServiceWorker() {
    if (this.isServiceWorkerCompatible(window)) {
      console.log('Service worker register...');
      // console.log(navigator);
      if (navigator.serviceWorker) {
        navigator.serviceWorker.register('/ngsw-worker.js', { scope: '/' })
          .then( (reg) => {
              console.log('Service worker registration succeeded:', reg);
            },
            (err) => {
              console.log('Service worker registration failed:', err);
            }
          );
      }
    }
  }
  public isServiceWorkerCompatible(window: Window): boolean {
    /*console.log('Window Agent : ' + window.navigator.userAgent
      + ' | ' + 'IsSafari:' + this.isSafari(window) +
      ' | ' + 'isIOS:' + this.isIOS(window) +
      ' | ' + 'isEdgePrivateMode:' + this.isEdgePrivateMode(window) +
      ' | ' + 'isPrivateMode:' + !window.indexedDB);*/
    return (
      !this.isIOS(window) &&
      !this.isSafari(window) &&
      !this.isEdgePrivateMode(window)
    );
  }
  private isIOS(window: Window): boolean {
    return ['iPad', 'iPhone', 'iPod'].indexOf(window.navigator.platform) >= 0;
  }
  private isSafari(window: Window): boolean {
    return (
      window.navigator.userAgent.search('Safari') >= 0 &&
      window.navigator.userAgent.search('Chrome') < 0
    );
  }
  private isEdge(window: Window): boolean {
    return window.navigator.userAgent.indexOf('Edge/') >= 0;
  }
  isEdgePrivateMode(window: Window) {
    let isPrivate = false;
    if (this.isEdge(window)) {
      try {
        if (!window.indexedDB) {
          isPrivate = true;
        }
      } catch (e) {
        isPrivate = true;
      }
    }
    return isPrivate;
  }
}
