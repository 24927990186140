import {HttpClient} from "@angular/common/http";
import {HttpService} from "../HttpService";
import {TokenService} from "../TokenService";
import {ConfService} from "../../services/ConfService";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {flatMap} from "rxjs/operators";
import {GenericLink_PendingBooking} from "../../../model/V3_GenericLink/GenericLink_PendingBooking";
import {GenericLinkPage} from "../../../model/V3_GenericLink/GenericLinkPage";
import {GenericLinkSearch} from "../../../model/V3_GenericLink/GenericLinkSearch";

@Injectable({ providedIn: 'root' })
export class GenericLinkEndpoint {
  constructor(private http: HttpClient,
              private httpService: HttpService,
              private tokenService: TokenService,
              private confService: ConfService) {
  }

  getPendingGenericLinkWithPage(search: GenericLinkSearch): Observable<GenericLinkPage> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<GenericLinkPage>(this.confService.getUrlApiEndPoint() + 'api-v2/generic-link/pending', search, this.httpService.getHeaders(to));
    }));
  }

  getPendingGenericLink(): Observable<GenericLink_PendingBooking[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<GenericLink_PendingBooking[]>(this.confService.getUrlApiEndPoint() + 'api-v2/generic-link/pending', this.httpService.getHeaders(to));
    }));
  }

  countPendingGenericLink(): Observable<number> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<number>(this.confService.getUrlApiEndPoint() + 'api-v2/generic-link/pending-count', this.httpService.getHeaders(to));
    }));
  }

  refusePendingGenericLink(genericLinkId: string): Observable<GenericLink_PendingBooking> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<GenericLink_PendingBooking>(this.confService.getUrlApiEndPoint() + 'api-v2/generic-link/refuse/' + genericLinkId, this.httpService.getHeaders(to));
    }));
  }

  validatePendingGenericLink(genericLink: GenericLink_PendingBooking): Observable<GenericLink_PendingBooking> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<GenericLink_PendingBooking>(this.confService.getUrlApiEndPoint() + 'api-v2/generic-link/validate', genericLink, this.httpService.getHeaders(to));
    }));
  }

}
