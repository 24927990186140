import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from 'firebase';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Observable } from 'rxjs';
import { FirebaseUser } from '../../model/firebase/FirebaseUser';
import { Person } from '../../model/Person';
import { TokenService } from './TokenService';

const YAAGO_FIREBASE_USER = 'yaago_firebase_user';
const YAAGO_USER = 'yaago_user';
const YAAGO_REGISTERED_USER = 'yaago_register_user';
const YAAGO_CAMPAIGN = 'yaago_campaign';

@Injectable({ providedIn: 'root' })
export class FirebaseService {

  constructor(private angularFireAuth: AngularFireAuth,
    private router: Router,
    // private cookieService: CookieService,
    private tokenService: TokenService) {
    this.angularFireAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(p => { });
  }

  getCurrentUser(): FirebaseUser {
    return JSON.parse(localStorage.getItem(YAAGO_FIREBASE_USER));
  }
  setCurrentUser(refFirebaseUser: FirebaseUser) {
    localStorage.setItem(YAAGO_FIREBASE_USER, JSON.stringify(refFirebaseUser));
  }

  getCurrentCampaign(): string {
    return JSON.parse(localStorage.getItem(YAAGO_CAMPAIGN));
  }
  setCurrentCampaign(refCampaign: string) {
    localStorage.setItem(YAAGO_CAMPAIGN, JSON.stringify(refCampaign));
  }

  getCurrentRegisterUser(): Person {
    console.log('localstorage registereduser', localStorage.getItem(YAAGO_REGISTERED_USER));
    return JSON.parse(localStorage.getItem(YAAGO_REGISTERED_USER));
  }
  setCurrentRegisterUser(refRegisterUser: Person) {
    localStorage.setItem(YAAGO_REGISTERED_USER, JSON.stringify(refRegisterUser));
  }
  removeCurrentRegisterUser() {
    localStorage.removeItem(YAAGO_REGISTERED_USER);
  }

  firebaseUserState(): Observable<User> {
    return this.angularFireAuth.authState;
  }

  googleLogin(): Promise<any> {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    return this.socialMediaLogin(provider);
  }
  facebookLogin(): Promise<any> {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.socialMediaLogin(provider);
  }
  emailLogin(email: string, password: string): Promise<any> {
    // try to sign out first prevent some strange behaviours ...
    this.angularFireAuth.auth.signOut();
    return this.angularFireAuth.auth.signInWithEmailAndPassword(email, password);

  }

  emailToken(token: string): Promise<any> {
    // try to sign out first prevent some strange behaviours ...
    this.angularFireAuth.auth.signOut();
    return this.angularFireAuth.auth.signInWithCustomToken(token);

  }
  socialMediaLogin(provider: any) {
    return new Promise<any>((resolve, reject) => {
      this.angularFireAuth.auth.signInWithPopup(provider).then(res => {
        console.log(res);
      }, err => {
        console.log(err);
        console.log('error socialMedia');
      });
    });
  }

  create(refEmail: string, refPassword: string): Promise<firebase.auth.UserCredential> {
    return this.angularFireAuth.auth.createUserWithEmailAndPassword(refEmail, refPassword);
  }

  signOut() {
    this.angularFireAuth.auth.signOut().then(p => {
      this.angularFireAuth.authState.pipe().subscribe(s => {
        console.log(s);
      });
      localStorage.removeItem(YAAGO_FIREBASE_USER);
      localStorage.removeItem(YAAGO_USER);
      localStorage.removeItem(YAAGO_CAMPAIGN);
      return this.tokenService.removeToken();
    });
  }

  changePassword(newPassword): Promise<void> {
    const user = firebase.auth().currentUser;
    return user.updatePassword(newPassword);
  }

  updateUserPhotoURL(photoUrl: string): Promise<void> {
    const user = firebase.auth().currentUser;
    return user.updateProfile({
      photoURL: photoUrl,
    });
  }
  verifyEmail(oobCode: string, lang: string): Promise<void> {
    firebase.auth().languageCode = lang || 'en';
    return this.angularFireAuth.auth.applyActionCode(oobCode);
  }

  resetPassword(newPassword: string, oobCode: string, lang: string): Promise<void> {
    firebase.auth().languageCode = lang || 'en';
    return this.angularFireAuth.auth.confirmPasswordReset(oobCode, newPassword);
  }

}
