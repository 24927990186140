import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'customMoment'
})
export class CustomMomentPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  // yaago - Mer 01 sept
  // yaagoFull - Mer 01 sept 2021
  // yaagoTime - 11h22
  transform(value: string | number | Date, language?: string, format: string = 'yaago', useAppLocal: boolean = false): any {
    const lang = language || navigator.language || window.navigator.language;
    const locale = useAppLocal ? moment.locale(this.translateService.currentLang) : moment.locale(lang);
    moment.locale(locale);

    if (value) {
      let v = value;
      // Format string when data arrive when a shitty format.
      if (typeof value === 'string' && value.lastIndexOf('[') !== -1) {
        v  = value.substring(0, value.lastIndexOf('['));
      }

      if ('yaago' === format || 'yaagoFull' === format) {
        const date = moment(v);
        const day = date.format('dddd').substring(0, 3);
        const month = date.format('MMMM').substring(0, 4);

        let year = '';
        if ('yaagoFull' === format) {
          year = date.format('YYYY');
        }
        return `${day} ${date.date()} ${month} ${year}`;
      } else if ('yaagoTime' === format){
        const date = moment(v);
        const hours = date.format('HH');
        const min = date.format('mm');
        return `${hours}h${min}`;
      } else {
        return moment(v).format(format);
      }
    } else {
      return '';
    }
  }

}
