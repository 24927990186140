import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../HttpService';
import {TokenService} from '../TokenService';
import {ConfService} from '../../services/ConfService';
import {RequestLogin} from '../../../model/bookingdotcom/RequestLogin';
import {Observable} from 'rxjs';
import {ActionResponse} from '../../../model/bookingdotcom/ActionResponse';
import {flatMap} from 'rxjs/operators';
import {ResponseToCall} from '../../../model/bookingdotcom/ResponseToCall';
import {RequestCall} from '../../../model/bookingdotcom/RequestCall';
import {Person} from '../../../model/Person';
import {RequestToVerifyCode} from '../../../model/bookingdotcom/RequestToVerifyCode';
import {LoginResponse} from '../../../model/bookingdotcom/LoginResponse';
import {Listing} from '../../../model/airbnb/Listing';
import {LiveProperty} from '../../../model/bookingdotcom/LiveProperty';
import {
  FE_RequestForCode, FE_RequestSendCode,
  V3_NewCodeResponse, V3_NewCodeSendResponse,
  V3_NewPasswordResponse
} from "../../../model/bookingdotcom/V3_NewPasswordResponse";
import {BookingDotComAccount} from "../../../model/bookingdotcom/BookingDotComAccount";

@Injectable({ providedIn: 'root' })
export class BookingDotComEndpoint {
  constructor(private http: HttpClient,
              private httpService: HttpService,
              private tokenService: TokenService,
              private confService: ConfService) {
  }

  V3GetBookingDotComChallenge() : Observable<BookingDotComAccount[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<BookingDotComAccount[]>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/V3-get-booking-challenge',this.httpService.getHeaders(to));
    }));
  }


  V3RequestChallengecode(bookingDotComAccount: BookingDotComAccount) : Observable<string> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<string>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/V3-request-challenge-code', bookingDotComAccount, this.httpService.getHeaders(to));
    }));
  }

  V3ChallengeSendCode(challengeSendCode: ChallengeSendCode) : Observable<SendCodeForChallengeResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<SendCodeForChallengeResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/V3-send-challenge-code', challengeSendCode, this.httpService.getHeaders(to));
    }));
  }

  V3NewBookingDotComLogin(requestLogin: RequestLogin): Observable<V3_NewPasswordResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<V3_NewPasswordResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/V3-login', requestLogin, this.httpService.getHeaders(to));
    }));
  }
  V3NewBookingDotComRequestForCode(feRequestForCode: FE_RequestForCode): Observable<V3_NewCodeResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<V3_NewCodeResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/V3-request-for-code', feRequestForCode, this.httpService.getHeaders(to));
    }));
  }

  V3NewBookingDotComSendCode(feRequestSendCode: FE_RequestSendCode): Observable<V3_NewCodeSendResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<V3_NewCodeSendResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/V3-send-code', feRequestSendCode, this.httpService.getHeaders(to));
    }));
  }





  bookingDotComLogin(requestLogin: RequestLogin): Observable<ActionResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<ActionResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking', requestLogin, this.httpService.getHeaders(to));
    }));
  }




  bookingDotComDeleteAccount(accountEmail: string): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/' + accountEmail, this.httpService.getHeaders(to));
    }));
  }
  bookingDotComDeleteAccountByEmail(bookingDotcomeEmail: string): Observable<Person> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<Person>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/' + bookingDotcomeEmail, this.httpService.getHeaders(to));
    }));
  }
  bookingDotComRequest(requestCall: RequestCall): Observable<ResponseToCall> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<ResponseToCall>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/otp/request', requestCall, this.httpService.getHeaders(to));
    }));
  }
  bookingDotComVerifyCode( requestToVerifyCode: RequestToVerifyCode ): Observable<ActionResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<ActionResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/otp/verify', requestToVerifyCode, this.httpService.getHeaders(to));
    }));
  }
  getBookingDotcomProperties(): Observable<LoginResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<LoginResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/properties', this.httpService.getHeaders(to));
    }));
  }
  getBookingDotcomPropertiesByEmail(bookingDotComEmail: string): Observable<LoginResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<LoginResponse>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/properties/' + bookingDotComEmail, this.httpService.getHeaders(to));
    }));
  }
  getBookingDotcomBooking(): Observable<any> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<any>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/bookings', this.httpService.getHeaders(to));
    }));
  }
  getBookingDotcomBookingByEmail(bookingDotcomEmail: string): Observable<any> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<any>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/bookings/' + bookingDotcomEmail, this.httpService.getHeaders(to));
    }));
  }
  getRawProperties(): Observable<LiveProperty[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<LiveProperty[]>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/raw-properties', this.httpService.getHeaders(to));
    }));
  }
  getRawPropertiesByEmail(bookingDotComEmail: string): Observable<LiveProperty[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<LiveProperty[]>(this.confService.getUrlApiEndPoint() + 'api-v2/person/booking/raw-properties/' + bookingDotComEmail, this.httpService.getHeaders(to));
    }));
  }
}
export class ChallengeSendCode {
  bookingDotComAccount: BookingDotComAccount;
  code: string;
}

export class SendCodeForChallengeResponse {
  nextStep: string;
  redirectUri: string;
}
