import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TarifComponent } from './modules/tarif/tarif-page/tarif.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },

  {
    path: 'register',
    loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: 'sign-in-premium',
    loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/registration/registration.module').then(mod => mod.RegistrationModule)
  },
  {
    path: 'property',
    loadChildren: () => import('./modules/property/property.module').then(mod => mod.PropertyModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(mod => mod.DashboardModule)
  },
  {
    path: 'setup',
    loadChildren: () => import('./modules/setup/setup.module').then(mod => mod.SetupModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./modules/support/support.module').then(mod => mod.SupportModule)
  },
  {
    path: 'recommendation',
    loadChildren: () => import('./modules/recommendation/recommendation.module').then(mod => mod.RecommendationModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./modules/calendar/calendar.module').then(mod => mod.CalendarModule)
  },
  {
    path: 'misc',
    loadChildren: () => import('./modules/misc/misc.module').then(mod => mod.MiscModule)
  },
  {
    path: 'blackfriday',
    loadChildren: () => import('./modules/notlogged/notlogged.module').then(mod => mod.NotloggedModule)
  },
  {
    path: 'tarif',
    loadChildren: () => import('./modules/tarif/tarif.module').then(mod => mod.TarifModule)
  },


  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
