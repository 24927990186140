import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule, FirebaseOptions, FirebaseOptionsToken } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import player from 'lottie-web';
import { IntercomModule } from 'ng-intercom';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import {
  GoogleAnalyticsService, IGoogleAnalyticsSettings,
  NgxGoogleAnalyticsModule, NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN
} from 'ngx-google-analytics';
import { LottieModule } from 'ngx-lottie';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../modules/shared/shared.module';
import { MobileBottomMenuComponent } from './components/bottom-menu/mobile-bottom-menu/mobile-bottom-menu.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { Yaago2LoaderComponent } from './components/yaago2-loader/yaago2-loader.component';
import { BugsnagChunkLoadErrorHandler } from './handler/BugsnagChunkLoadErrorHandler';
import {Yaago2TopnavbarComponent} from "./components/yaago2-topnavbar/yaago2-topnavbar.component";
import {RouterModule} from '@angular/router';

export function getGA(): IGoogleAnalyticsSettings {
  return {
    trackingCode: window['ganalytics'],
    initCommands: [],
    uri: undefined,
    enableTracing: undefined,
    nonce: undefined
  };
}

export function errorHandlerFactory() {
  return new BugsnagChunkLoadErrorHandler();
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function firebaseOptions(): FirebaseOptions {
  return window['firebaseConfig'] as FirebaseOptions;
}

export function intercomAppId(): string {
  return window['intercomAppId'] as string;
}

export function cookieOptions(): NgcCookieConsentConfig {
  return {
    cookie: {
      domain: window['domain'] // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    palette: {
      popup: {
        background: '#03224c',
        text: '#FFFFFF'
      },
    },
    type: 'info',
  };
}

export function playerFactory() {
  return player;
}


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        {
            ngModule: AngularFireModule,
            providers: [
                {provide: FirebaseOptionsToken, useFactory: firebaseOptions},
            ]
        },
        AngularFireAuthModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IntercomModule.forRoot({
            appId: intercomAppId(), // from your Intercom config
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
        }),
        NgcCookieConsentModule.forRoot(cookieOptions()),
        SharedModule,
        LottieModule.forRoot({player: playerFactory}),
        QuillModule.forRoot(),
        {
            ngModule: NgxGoogleAnalyticsModule,
            providers: [
                {
                    provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, useFactory: getGA
                },
                NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER
            ]
        },
        RouterModule
    ],
  providers: [
    GoogleAnalyticsService,
    { provide: BugsnagChunkLoadErrorHandler, useFactory: errorHandlerFactory },
    /*  TODO: See with Yassir if its needed as we already have bugsnag
        {
          // processes all errors
          provide: ErrorHandler,
          useClass: GlobalErrorHandler
        }*/
  ],
    exports: [
        // Module
        LottieModule,
        // Components
        Yaago2TopnavbarComponent,
        MobileBottomMenuComponent,
        SnackbarComponent,
        Yaago2LoaderComponent,
        LoaderComponent,
    ],
  declarations: [
    MobileBottomMenuComponent,
    Yaago2TopnavbarComponent,
    SnackbarComponent,
    Yaago2LoaderComponent,
    LoaderComponent,
  ]
})
export class CoreModule {
}
