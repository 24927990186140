import {Injectable} from "@angular/core";
import { Crisp } from "crisp-sdk-web";
import {FirebaseUser} from "../../model/firebase/FirebaseUser";
import {PersonEndpoint} from "./YaagoEndpoints/PersonEndpoint";
import {Person} from "../../model/Person";
import {PersonService} from "../services/YaagoServices/PersonService";

@Injectable({ providedIn: 'root' })
export class CrispService {

  myCorporateOwner: Person;
  crispData: any;
  constructor(private personEndpoint: PersonEndpoint,
              private personService: PersonService) { }
  public init() {
    Crisp.configure("5701b93f-709f-483c-8081-3ad9bdc51880", {autoload: false});
    // Crisp.session.reset(true);
    // Crisp.setPosition('right');
  }

  setUser(fbUser: FirebaseUser) {

    const myProfile = this.personService.getCurrentUser();
    //console.log("---->> MYPROFILE", myProfile);
    Crisp.user.setEmail(myProfile.email);
    if (myProfile.corporate?.name) {
      Crisp.user.setCompany(myProfile.corporate?.name, null);
    }
    if (myProfile.phone) {
      Crisp.user.setPhone(myProfile?.phone);
    }
    Crisp.user.setNickname(myProfile?.firstName + " " + myProfile?.lastName);


    if (myProfile.pictureUrl) {
      Crisp.user.setAvatar(myProfile.pictureUrl.split(' ').join('%20'));
      //console.log("Avatar", myProfile.pictureUrl.split(' ').join('%20'));
    } else if (myProfile.corporate != null && myProfile.corporate.backofficeLogoUrl) {
      Crisp.user.setAvatar(myProfile.corporate.backofficeLogoUrl.split(' ').join('%20'));
      //console.log("Avatar", myProfile.corporate.backofficeLogoUrl.split(' ').join('%20'));
    } else {
    }

    // console.log("Email", Crisp.user.getEmail());

    //this.personEndpoint.getMyCorporateOwner()
//      .subscribe(res => {
        //console.log("---->>>> CORPORAOWNER" ,res);
  //      this.myCorporateOwner = res;
    //    let myRole: string;


        /*if (this.myCorporateOwner.corporatesRoles?.length > 0) {
          this.myCorporateOwner.corporatesRoles.forEach(corporateRole => {
            //console.log('myRoleId', corporateRole.roleId);
            if (corporateRole.corporateId === myProfile.corporateId) {
          //    console.log('myRoleId with Corporate', corporateRole.roleId);
              if (myProfile.corporate.availableRoles?.length > 0) {
                myProfile.corporate.availableRoles.forEach(role => {
                  if (role.id === corporateRole.roleId) {
                    //console.log('myRoleName with Corporate', role.displayableName);
                    myRole = role.displayableName;
                  }
                });
              }
            }
          });
        }*/
        //let subscription= '';
        //if (this.myCorporateOwner) {
          // subscription = this.myCorporateOwner.subscriptionName;
        //}
        //this.crispData = [["myRole", myRole]];

        /*this.crispData = [
          ["subscriptionPlan", subscription],
          ["myRole", myRole]
        ]*/

        //Crisp.session.setData(this.crispData);
      //});








  }

  openMessenger() {
    Crisp.chat.open();

  }
  showMessenger() {
    Crisp.chat.show();

  }
  hideMessanger() {
    // console.log("HIDE");
    Crisp.chat.hide();
  }

  closeMessenger() {
    // console.log("CLOSE");
    Crisp.chat.close();
  }
}
